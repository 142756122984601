import React, {Component} from 'react';
import ArchivedProject from './archivedProject';
import {Form} from 'react-bootstrap';
import {API} from "aws-amplify";

class ArchivedProjects extends Component {

    state = {
        projects: "fetching",
        displayedProjects: [] ,
        searchString: '',
        searchField: 'name'
    }

    constructor(props){
        super(props);

        this.handleRestore = this.handleRestore.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleSearchFieldSelect = this.handleSearchFieldSelect.bind(this)
    }

    async onSearch(event) {
        const searchString = event.target.value;

        await this.setState({searchString});

        this.handleSearch();
    }

    handleSearch(){

        const displayedProjects = this.state.projects.filter(project => project[this.state.searchField].indexOf(
            this.state.searchString) > -1);

        console.log(displayedProjects);

        this.setState({displayedProjects});

    }

    async handleSearchFieldSelect(event){

        const searchField = event.target.value;

        await this.setState({searchField});

        this.handleSearch()
    }

    async handleRestore(event){

        const restoredProjectId = event.target.value;

        const restoreResult = await API.patch('soaptwo', '/projects/' + restoredProjectId, {
            body: {'archived': 0}
        });

        if(restoreResult[0].archived == 0) {

            const projects = this.state.projects.filter(project => project.id != restoredProjectId);

            this.setState({projects});

            this.handleSearch();

        }
    }

    async componentDidMount(){
        const projectsResult = await API.get('soaptwo', '/archived-projects');

        this.setState({projects: projectsResult});

        this.handleSearch();
    }

    render(){
        return (
            <React.Fragment>

                <Form.Group>
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                        type="text"
                        name="searchString"
                        value={this.state.searchString}
                        onChange={this.onSearch}
                    />

                    <Form.Group>
                        {[
                            {propertyName: 'name', label: "Project Name"},
                            {propertyName: 'archived_by', label: "User name"}].map(field =>
                            <Form.Check
                                type="radio"
                                value={field.propertyName}
                                label={field.label}
                                checked={this.state.searchField === field.propertyName}
                                onClick={this.handleSearchFieldSelect}
                            />
                        )}
                    </Form.Group>

                </Form.Group>


            <table id="list_table" className="table main_table">
                <tr className="nohov_btn white">
                    <th>Project name</th>
                    <th>User name</th>
                    <th>Start date</th>
                    <th>Date closed</th>
                    <th>Invites sent</th>
                    <th>Survey completes</th>
                    <th>Started %</th>
                    <th>Response rate %</th>
                    <th>Completion rate %</th>
                    <th>Drop out %</th>
                    <th>Restore</th>
                </tr>

                {this.state.projects !="fetching" && this.state.displayedProjects.map(project =>

                    <ArchivedProject
                        project={project}
                        onRestore={this.handleRestore}
                    />

                )}

            </table>

            </React.Fragment>
        )
    }
}

export default ArchivedProjects;
