import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import ErrorDisplayingFormField from "./errorDisplayingFormField";

class ExternalSample extends Component {

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {

        const sends = event.target.value;

        this.props.onChangeSend(this.props.supplier.id, sends)
    }

    render() {
        return (
            <div>
                <span>{this.props.supplier.name}</span>

                <ErrorDisplayingFormField
                    type="text"
                    onChange={this.handleChange}
                    name={this.props.supplier.id + "-supplier-sends"}
                    value={this.props.supplier.sent}
                    errors={this.props.errors}
                    label="Sends"
                />

                <Button className="nohov_btn" id="setup_project_del_btn"variant="danger"
                        onClick={() => this.props.onDelete(this.props.supplier.id)}
                >Delete</Button>
            </div>
        )

    }
}

export default ExternalSample;
