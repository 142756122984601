import React, {Component} from "react";
import {Form, Button} from 'react-bootstrap';
import ExternalSample from './externalSample.jsx';
import {Link} from "react-router-dom";

class ExternalSamples extends Component {

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this);
    }

    state = {
        externalSampleSupplierIdToAdd: null,
    }


    handleChange(event) {

        const supplierId = event.target.value;

        this.setState({
            externalSampleSupplierIdToAdd: supplierId
        })
    }

    render() {
        return (

            <React.Fragment>
            {this.props.externalSampleSuppliers != "fetching" &&
            this.props.selectedExternalSampleSuppliers
            && this.props.selectedExternalSampleSuppliers.map((supplier) =>
                  <ExternalSample
                        supplier={supplier}
                        onChangeSend={this.props.onChangeExternalSampleSend}
                        onDelete={this.props.onDeleteExternalSample}
                        errors={this.props.errors}
                    />
            )}
            <div>
            <br></br>
            <Form.Group controlId="supplier_name">
                <Form.Label>Supplier Name</Form.Label>
                <Form.Control as="select"
                    name="externalSampleSupplierToAdd"
                    onChange={this.handleChange}
                    value = {this.state.externalSampleSupplierIdToAdd}
                >
                    <option value="0">Select supplier</option>
                    {this.props.externalSampleSuppliers != "fetching" &&
                    this.props.externalSampleSuppliers.filter(supplier => !supplier.selected).map(supplier =>
                        <option value={supplier.id}>{supplier.name}</option>
                    )}
                </Form.Control>
            </Form.Group>
                <Button 
                    variant="primary"
                    onClick={() => this.props.onAddExternalSample(this.state.externalSampleSupplierIdToAdd)}
                >Add</Button>
            </div>

                <Link to={"/administration/"}>Administration Settings (click here to add a new External Supplier)</Link>

            <hr></hr>
            </React.Fragment>
        )
    }
}

export default ExternalSamples;
