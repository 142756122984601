import React, {Component} from 'react';
import {Card} from 'react-bootstrap';

class Expandable extends Component {

    state = {
        expanded: false
    }

    constructor(props) {
        super(props);

        this.handleExpand = this.handleExpand.bind(this);
    }

    handleExpand(){
        if(this.state.expanded) {
            this.setState({expanded: false})
        } else {
            this.setState({expanded: true})
        }
    }

    render() {
        return (
            <Card>
                <Card.Body>
                <Card.Title>
                    <span onClick={this.handleExpand}>[{this.state.expanded ? '-' : '+'}]</span> {this.props.title}
                </Card.Title>

                {this.state.expanded && this.props.children}
                </Card.Body>
            </Card>
        )
    }
}

export default Expandable;