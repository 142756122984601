import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

class Incentive extends Component {

    constructor(props){
        super(props);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleEdit() {
        this.props.onEdit({
            quota: this.props.incentive.quota,
            filter: this.props.incentive.filter,
            points: this.props.incentive.points,
        });


        //JFTODO make this less hacky

        this.props.onDelete(this.props.incentive.id)
    }

    handleDelete() {
        this.props.onDelete(this.props.incentive)
    }

render() {
    return (
        <tr>
            <td className="nohov_btn">{this.props.incentive.type}</td>
            <td className="nohov_btn">{this.props.incentive.name}</td>
            <td className="nohov_btn">{this.props.incentive.points}</td>
            <td className="nohov_btn"><Button
                variant="secondary"
                onClick={this.handleEdit}
            >Edit</Button></td>
            <td className="nohov_btn"><Button
                variant="danger"
                onClick={this.handleDelete}
            >Delete</Button></td>
        </tr>
    )
}
}

export default Incentive;
