import React, {Component} from 'react';
import {Button, Modal, Form, Tabs, Tab} from "react-bootstrap";

class EmailTemplateEditor extends Component{

    state = {
        activeTab: 'source'
    };

    render() {
        return(
            <Modal
                show={this.props.open}
                dialogClassName="mail-template-modal"
            >
                <Modal.Body>

                    <Form.Group>
                        <Form.Label>
                            Name
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={this.props.template.name}
                            onChange={this.props.onPropertyChange}
                        />
                        <Form.Label>
                            Tags
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="tags"
                            value={this.props.template.tags}
                            onChange={this.props.onPropertyChange}
                        />
                    </Form.Group>

                    <div> Available dynamic text fields:
                        <span> [[link]]</span>
                        <span> #first_name#</span>
                        {this.props.dynamicFields != "fetching" && this.props.dynamicFields.map(field =>

                            <span> {field.varname}</span>

                        )}
                    </div>

                    <Tabs>
                        <Tab
                            eventKey="source"
                            title="source"
                        >
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    rows="10"
                                    name="content"
                                    value={this.props.template.content}
                                    onChange={this.props.onPropertyChange}
                                />
                            </Form.Group>
                        </Tab>
                        <Tab
                            eventKey="preview"
                            title="Preview"
                        >
                            <div
                                dangerouslySetInnerHTML={{__html: this.props.template.contentPreview}}
                            ></div>
                        </Tab>
                    </Tabs>


                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={this.props.toggleOpen}
                    >Cancel</Button>
                    <Button
                        variant="primary"
                        onClick={this.props.onSave}
                    >Save</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default EmailTemplateEditor;