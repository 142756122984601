import React, {Component} from 'react';
import ReturnParameter from './returnParameter';
import {Form, Button} from 'react-bootstrap';
import{API} from "aws-amplify";

class ReturnParameters extends Component {


    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.state = {parameters: "fetching"};

        if(this.props.type == "uniqueIdentifier") {
            this.state.endPoint = 'uniqueidentifierparams';
        }
        else {
            this.state.endPoint = 'resultcodeparams';
        }
    }

    async componentDidMount() {

        const result = await API.get('soap', '/' + this.state.endPoint);

        this.setState({parameters: result});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleAdd(event){

        const result = await API.post('soap', '/' + this.state.endPoint, {
           body: {name: this.state.newParameter}
        });

        if(!result.code || result.code != 1000) {

            const newParameter = result[0];

            const parameters = [...this.state.parameters, newParameter];

            this.setState({parameters});
        }

    }

    async handleDelete(event){

        const id = event.target.value;

        const result = await API.del('soap', '/' + this.state.endPoint + "/" + id)

        const parameters = this.state.parameters.filter(parameter => {
            return parameter.id != id;
        });

        this.setState({parameters});
    }

    render() {
        return (

            <React.Fragment>
                {this.state.parameters != 'fetching' &&
                this.state.parameters.map( parameter =>
                    <ReturnParameter
                        parameter={parameter}
                        onDelete={this.handleDelete}
                    />

                )}
                <Form.Group>
                    <Form.Label>Add Parameter</Form.Label>
                    <Form.Control id="param-form"
                        name="newParameter"
                        type="text"
                        value={this.state.newParameter}
                        onChange={this.handleInputChange}
                    />
                </Form.Group>

                <Button
                    variant="primary"
                    onClick={this.handleAdd}
                >Add</Button>

            </React.Fragment>
        )
    }

}

export default ReturnParameters;
