import React, {Component} from 'react';
import Faker from 'faker';
import {Link} from 'react-router-dom';
import {Table, Form, Button, FormControl} from 'react-bootstrap';
import Expandable from './expandable.jsx';
import YouthsightUrlForClient from "./youthsightUrlForClient";
import PanelVariables from "./panelVariables";
import Quotas from "./quotas";
import Filters from "./filters";
import Samples from "./samples";
import Incentives from "./incentives";
import TestUrls from "./testUrls";
import Testers from "./testers";
import InvitesReminders from "./invitesReminders";
import YouthsightUrlsForSupplier from "./youthsightUrlsForSupplier";
import SupplierUrlsForYouthsight from "./supplierUrlsForYouthsight";
import ParameterSelector from "./parameterSelector";
import {API} from "aws-amplify";
import EditableText from "./editableText";
import ErrorDisplayingFormField from "./errorDisplayingFormField";
import moment from 'moment';
import Navigation from "./navigation";
import EditSentCount from "./editSentCount";
import CompletionStatsTable from "./completionStatsTable";

class viewProject extends Component {


    constructor(props) {
        super(props)

        this.handleFilteringStatsUpdate = this.handleFilteringStatsUpdate.bind(this);

        this.handleSetTarget = this.handleSetTarget.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleClientSurveyUrlSave = this.handleClientSurveyUrlSave.bind(this);
        this.handleClientIdParamNameSave = this.handleClientIdParamNameSave.bind(this);

        this.handleAddPanelVariable = this.handleAddPanelVariable.bind(this);

        this.handleQuotaStatusChange = this.handleQuotaStatusChange.bind(this);
        this.handleQuotaDelete = this.handleQuotaDelete.bind(this);
        this.handleQuotaSave = this.handleQuotaSave.bind(this);
        this.handleQuotaEdit = this.handleQuotaEdit.bind(this);

        this.sourcesSentRefresh = this.sourcesSentRefresh.bind(this)

        this.handleSamplesSearch = this.handleSamplesSearch.bind(this);
        this.handleAddSample = this.handleAddSample.bind(this);
        this.handleSampleRefresh = this.handleSampleRefresh.bind(this);

        this.pollForNewSelectedSample = this.pollForNewSelectedSample.bind(this);
        this.pollForRefreshedSample = this.pollForRefreshedSample.bind(this);

        this.handleFilterSave = this.handleFilterSave.bind(this);
        this.handleFilterDelete = this.handleFilterDelete.bind(this);
        this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
        this.handleFiltersRecalculate = this.handleFiltersRecalculate.bind(this);

        this.handleIncentiveDelete = this.handleIncentiveDelete.bind(this);
        this.handleIncentiveSave = this.handleIncentiveSave.bind(this);

        this.handleProjectClosedToggle = this.handleProjectClosedToggle.bind(this);

        this.handleParamSave = this.handleParamSave.bind(this);

        this.handleSetDefaultIncentive = this.handleSetDefaultIncentive.bind(this);

        this.handleInviteToggle = this.handleInviteToggle.bind(this);
        this.handleSelectQuota = this.handleSelectQuota.bind(this);
        this.onStatusReminderToggle = this.onStatusReminderToggle.bind(this);
        this.handleSetQuotaCombinationsAvailableStat = this.handleSetQuotaCombinationsAvailableStat.bind(this);
        this.handleSelectQuotas = this.handleSelectQuotas.bind(this);
        this.setTotalToSend = this.setTotalToSend.bind(this);
        this.handleToSendChange = this.handleToSendChange.bind(this);
        this.handleDistribute = this.handleDistribute.bind(this);
        this.handleTotalToSendChange = this.handleTotalToSendChange.bind(this)
        this.handleSend = this.handleSend.bind(this)

        const projectName = Faker.random.number(3) > 0 ? Faker.company.companyName() : null;

        this.state = {
            projectId: this.props.match.params.id,
            projectClosed: "fetching",
            projectNumber: "fetching",
            totalAvailable: 0,
            totalCompletes: 0,
            totalSent: 0,
            totalStarted: 0,
            totalScreenOuts: 0,
            totalQuoataFull: 0,
            totalQualityFail: 0,
            target: "fetching",
            sources: "fetching",
            completionCode: 0,
            selectedPanelVariables: [],
            quotas: "fetching",
            quotasLastRecalculated: "fetching",
            samples: "fetching",
            selectedSamples: "fetching",
            displayedSamples: [],
            filters: "fetching",
            filtersLastRecalculated: "fetching",
            defaultIncentive: "fetching",
            emailTemplates: "fetching",
            selectedEmailTemplate: null,
            users: "fetching",
            errors: {},
            client_survey_url: "fetching",
            clientIdParamName: "fetching",
            clientResultCodeParam: "fetching",
            invite: true,
            remindStatus: 'invited',
            selectedQuotas: [],
            totalStatusStats: "fetching",
            quotaCombinations: [],
            quotasTotalAvailableStat: 0,
            totalToSend: 0,
            distributeToSend: false,
            xToGet: 7,
            invitesRemindersIsError: false,
            invitesRemindersErrorMessage: '',
            sendBlocked: false
        }

    }

    async handleFilteringStatsUpdate() {
        try {
            const result = await API.get('soap', "/projects/" + this.props.match.params.id + "/filters");

            this.setState({filters: result, filtersLastRecalculated: new Date()});

        } catch(e) {
            alert(e);
        }

        try {
            const result = await API.get('soap', '/projects/' + this.props.match.params.id + '/groups');

            const selectedSamples = result.map(function(selectedSample){
                selectedSample.id = selectedSample.sample_id;

                selectedSample.name = this.state.samples.filter(sample => {
                    return sample.sampleId == selectedSample.id;
                })[0].name;

                selectedSample.exclusions = selectedSample.filtered_out;
                selectedSample.lastRefreshed = new Date(selectedSample.last_refreshed);

                return selectedSample;
            }, this);

            this.setState({selectedSamples});
        } catch(e) {
            alert(e);
        }

        try {
            const result = await API.get('soap', '/projects/' + this.props.match.params.id + '/quotas');

            this.setState({quotas: result, quotasLastRecalculated: new Date()});
        } catch(e) {
            alert(e);
        }

        try {
            let statusStatsResult = await API.get('soaptwo', '/project-status-stats/' +
                this.state.projectId);

            await Promise.all([
                this.setState({totalStatusStats: statusStatsResult}),
                this.handleSetQuotaCombinationsAvailableStat()
            ]);

            await this.handleSelectQuotas();

            this.setTotalToSend();
        } catch(e) {
            alert(e);
        }
    }

    async componentDidMount() {

        try {
            const selectedPanelVariables = await API.get('soap', '/projects/' + this.props.match.params.id + '/panelvariables');

            this.setState({selectedPanelVariables})

        } catch (e) {
            alert(e)
        }

        try {
            const groupsResult = await API.get("soap", "/groups/");

            this.setState({samples: groupsResult});


        } catch (e) {
            alert(e)
        }

        try {
            const result = await API.get("soaptwo", "/projects/" + this.props.match.params.id);

            console.log(result);

            this.setState({
                projectId: result[0].id,
                projectNumber: result[0].job_number,
                projectName: result[0].name,
                projectType: result[0].type,
                client_survey_url: result[0].client_survey_url,
                clientIdParamName: result[0].client_id_param,
                startDate: new Date(result[0].start_date),
                clientUniqueIdentifierParam: {
                    id: result[0].unique_identifier_param_id,
                    name: result[0].unique_identifier_param
                },
                clientResultCodeParam: {
                    id: result[0].result_code_param_id,
                    name: result[0].result_code_param
                },
                defaultIncentive: result[0].default_incentive,
                target: result[0].target_completes,
                completionCode: result[0].complete_code,
                projectClosed: result[0].closed_date
            });

        } catch (e) {
            alert(e)
        }

        try {
            const result = await API.get('soaptwo', '/projects/' + this.props.match.params.id + "/suppliers");

            this.setState({sources: result});

            console.log(result);

            this.setState({
                totalAvailable: result.reduce((accumulator, current) => accumulator + current.sent + (current.unsent ? current.unsent : 0) + current.started, 0),
                totalCompletes: result.reduce((accumulator, current) => accumulator + current.completed, 0),
                totalUnsent: result.reduce((accumulator, current) => accumulator + (current.unsent ? current.unsent : 0), 0),
                totalSent: result.reduce((accumulator, current) => accumulator + current.sent, 0),
                totalStarted: result.reduce((accumulator, current) => accumulator + current.started, 0),
                totalScreenOuts: result.reduce((accumulator, current) => accumulator + current.screenout, 0),
                totalQuoataFull: result.reduce((accumulator, current) => accumulator + current.quota_full, 0),
                totalQualityFail: result.reduce((accumulator, current) => accumulator + current.quality_fail, 0)
            });

        } catch(e) {
            alert(e)
        }

        try {
            const result = await API.get('soap', '/projects/' + this.props.match.params.id + '/quotas');

            this.setState({quotas: result, quotasLastRecalculated: new Date()});
        } catch(e) {
            alert(e);
        }

        try {
            const result = await API.get('soap', '/projects/' + this.props.match.params.id + '/groups');

            const selectedSamples = result.map(function(selectedSample){
                selectedSample.id = selectedSample.sample_id;

                selectedSample.name = this.state.samples.filter(sample => {
                    return sample.sampleId == selectedSample.id;
                })[0].name;

                selectedSample.exclusions = selectedSample.filtered_out;
                selectedSample.lastRefreshed = new Date(selectedSample.last_refreshed);

                return selectedSample;
            }, this);

            this.setState({selectedSamples});
        } catch(e) {
            alert(e);
        }

        try {
            const result = await API.get('soap', "/projects/" + this.props.match.params.id + "/filters");

            this.setState({filters: result, filtersLastRecalculated: new Date()});

        } catch(e) {
            alert(e);
        }

        try {
            const emailTemplatesResult = await API.get('soaptwo', '/email-templates/');

            this.setState({emailTemplates: emailTemplatesResult});

            this.setState({emailTemplate: emailTemplatesResult[0].id})

        } catch(error) {
            console.log(error);
        }

        try {
            const users = await API.get('soapthree', '/users');

            this.setState({users})
        } catch(error) {
            console.log(error);
        }

        let statusStatsResult = await API.get('soaptwo', '/project-status-stats/' +
            this.state.projectId);

        this.setState({totalStatusStats: statusStatsResult});

    }

    async sourcesSentRefresh() {
        try {
            const result = await API.get('soaptwo', '/projects/' + this.props.match.params.id + "/suppliers");

            this.setState({sources: result});

            this.setState({
                totalAvailable: result.reduce((accumulator, current) => accumulator + current.sent + (current.unsent ? current.unsent : 0) + current.started, 0),
                totalCompletes: result.reduce((accumulator, current) => accumulator + current.completed, 0),
                totalUnsent: result.reduce((accumulator, current) => accumulator + (current.unsent ? current.unsent : 0), 0),
                totalSent: result.reduce((accumulator, current) => accumulator + current.sent, 0),
                totalStarted: result.reduce((accumulator, current) => accumulator + current.started, 0),
                totalScreenOuts: result.reduce((accumulator, current) => accumulator + current.screenout, 0),
                totalQuoataFull: result.reduce((accumulator, current) => accumulator + current.quota_full, 0),
                totalQualityFail: result.reduce((accumulator, current) => accumulator + current.quality_fail, 0)
            });

        } catch(e) {
            alert(e)
        }
    }

    async handleClientSurveyUrlSave(){

        const result = await API.patch('soaptwo', '/projects/' + this.props.match.params.id, {
            body: {'client_survey_url': this.state.client_survey_url}
        });

        const errors = result.errors ? result.errors : {};

        this.setState({errors: errors});

    }

    async handleClientIdParamNameSave(){

        const result = await API.patch('soaptwo', '/projects/' + this.props.match.params.id, {
            body: {'client_id_param': this.state.clientIdParamName}
        });

        const errors = result.errors ? result.errors : {};

        this.setState({errors: errors});

    }

    async handleSetTarget(target) {

        const projectId = this.props.match.params.id;

        let result = "";

        result = await API.patch('soaptwo', "/projects/" + projectId, {
                body:
                    {'target_completes': target}
            });

        if(result.code == 1000) {
            this.setState({errors: result.errors})

            return false;
        } else {

            this.setState({target: result[0].target_completes});

            return true;
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        console.log(name);

        this.setState({
            [name]: value
        });
    }

    async handleAddPanelVariable(event) {
        const selectedVariableId = event.target.value;

        await API.post('soap', '/projects/' + this.props.match.params.id + '/panelvariables', {
            body: {'variableId': selectedVariableId}
        });

        try {
            const selectedPanelVariables = await API.get('soap', '/projects/' + this.props.match.params.id + '/panelvariables');

            this.setState({selectedPanelVariables})

        } catch (e) {
            alert(e)
        }
    }

    async handleParamSave(paramType, paramFor, paramId, defaultParam) {

        let endPoint = "";

        let field = "";

        let input = {};

        if(paramFor === "client") {
            endPoint = "/projects/" + this.state.projectId

            if(paramType === "uniqueidentifier") {
                field = "unique_identifier_param_id";
            }
            else if(paramType === 'resultcode') {
                field = "result_code_param_id";
            }

            input[field] = paramId;
        }
        else {
            endPoint = "/projects/" + this.state.projectId +
                '/suppliers/' + paramFor

            if(paramType === "uniqueidentifier") {
                field = "unique_identifier_param_id";
            }
            else if(paramType === 'resultcode') {
                field = "result_code_param_id";
            }

            input[field] = paramId;
        }

        try {
            let result = await API.patch('soaptwo', endPoint, {body: input});

            const newParamId = result[0][field];

            if(paramFor === "client") {
                if(paramType === "uniqueidentifier") {

                    if(newParamId == 0) {
                        this.setState({clientUniqueIdentifierParam: {id: newParamId, name: defaultParam} });
                    } else {

                        let result = await API.get('soap', "/uniqueidentifierparams/" + newParamId);

                        this.setState({clientUniqueIdentifierParam: {id: newParamId, name: result[0].name}});
                    }
                }
                if(paramType === "resultcode") {

                    let result = await API.get('soap', "/resultcodeparams/" + newParamId);

                    this.setState({clientResultCodeParam: {id: newParamId, name: result[0].name} });

                }
            } else {
                if(paramType === "uniqueidentifier") {

                    let argumentName;

                    if(newParamId == 0) {
                        argumentName = defaultParam;
                    } else {
                        let result = await API.get('soap', "/uniqueidentifierparams/" + newParamId);

                        argumentName = result[0].name;
                    }

                    let sources = this.state.sources;

                    sources =  sources.map((source) => {
                        if (source.id === paramFor) {
                            source.unique_identifier_param_id = newParamId;
                            source.unique_identifier_param_name = argumentName;
                        }

                        return source;
                    });

                    console.log(sources);

                    this.setState({sources});
                }
            }

        } catch(e) {
            alert(e)
        }

    }

    async handleQuotaStatusChange(quota) {
        const id = quota.id;

        const result = await API.patch('soap', '/quotas/' + id, {
            body: {
                active: !quota.active + ""
            }
        });

        const quotas = this.state.quotas.map(quota => {
            if(quota.id == id) {
                quota.active = result[0].active;
                return quota;
            } else {
                return quota;
            }
        })

        this.setState({quotas});

        this.handleFilteringStatsUpdate();
    }

    async handleQuotaDelete(event){

        const id = event.target.value;

        const result = await API.del('soap', '/quotas/' + id);

        const quotas = this.state.quotas.filter(quota => {
            return quota.id != id;
        });

        this.setState({quotas});

    }

    async handleQuotaSave(quota) {

        let result;

        result = await API.post('soaptwo', '/sfdl-validate', {
            body: {
                definition: quota.newQuotaDefinition,
                projectId: this.state.projectId
            }
        });

        if(result.code == 1) {

            result = await API.post('soap', '/quotas', {
                body: {
                    name: quota.newQuotaName,
                    definition: quota.newQuotaDefinition,
                    target: quota.newQuotaTarget,
                    project_id: this.state.projectId + ""
                }
            });
        }

        if(result.code == 1000) {
            this.setState({errors: {quota: result.errors}})

            return false;
        } else {

            await this.handleFilteringStatsUpdate();

            return true;
        }
    }

    async handleQuotaEdit(quota) {

        const result = await API.patch('soap', '/quotas/' + quota.id, {
            body: {
                name: quota.name,
                definition: quota.definition,
                target: quota.target + "",
                active: quota.active + ""}
        });

        await this.handleFilteringStatsUpdate();
    }

    async handleFilterSave(filter){

        const projectId = this.props.match.params.id + "";

        let result;

        result = await API.post('soaptwo', '/sfdl-validate', {
            body: {
                definition: filter.newFilterDefinition,
                projectId: this.state.projectId
            }
        });

        if(result.code == 1) {

            const result = await API.post('soap', '/filters/', {
                body: {
                    name: filter.newFilterName,
                    definition: filter.newFilterDefinition,
                    project_id: projectId
                }
            });
        }

        if(result.code == 1000) {
            this.setState({errors: {filter: result.errors}})

            return false;
        } else {

            await this.handleFilteringStatsUpdate();

            return true
        }

    }

    async handleFilterDelete(event) {

        const id = event.target.value;

        const result = await API.del('soap', '/filters/' + id);

        await this.handleFilteringStatsUpdate();

    }

    async handleFilterStatusChange(filter) {

        const id = filter.id;

        const result = await API.patch('soap', '/filters/' + id, {
            body: {
                active: !filter.active + ""
            }
        });

        const filters = this.state.filters.map(filter => {
            if(filter.id == id) {
                filter.active = !filter.active;
                return filter;
            } else {
                return filter;
            }
        });

        this.setState({filters});

        this.handleFilteringStatsUpdate();
    }

    async handleFiltersRecalculate(){

        await this.handleFilteringStatsUpdate();

    }

    handleSamplesSearch(searchTerm){
        searchTerm = searchTerm.toLowerCase();

        console.log(searchTerm);

        const selectedSampleNames = this.state.selectedSamples.map(
            sample => sample.name);

        let displayedSamples = this.state.samples.filter(sample => {
            return !selectedSampleNames.includes(sample.name)
        });

        displayedSamples = displayedSamples.filter((sample) => {
            let searchValue = sample.name.toLowerCase() + ' ' + sample.psuName.toLowerCase();
            return searchValue.indexOf(searchTerm) !== -1;
        });

        this.setState({displayedSamples})
    }

    async handleAddSample(selectedSampleId) {

        this.setState({displayedSamples: []});

        this.pollForNewSelectedSample();

        const result = await API.post('soap', '/projects/' + this.props.match.params.id + '/groups', {
            body: {
                "sampleId": selectedSampleId
            }
        });
    }

    pollForNewSelectedSample() {

        async function checkAndUpdateSelectedSamples() {

            let selectedSamplesInDbResult = await API.get('soap', `/projects/${this.state.projectId}/groups`);

            let pageSampleCount = this.state.selectedSamples.length;

            let dbSampleCount = selectedSamplesInDbResult.length;

            console.log(dbSampleCount, pageSampleCount);

            if(dbSampleCount == pageSampleCount) {
                setTimeout(checkAndUpdateSelectedSamples.bind(this), 2000);
            } else {
                console.log("NEW SAMPLE!");

                await this.handleFilteringStatsUpdate();
            }

        }

        setTimeout(checkAndUpdateSelectedSamples.bind(this), 2000);

    }

    pollForRefreshedSample(sampleId, clickTime) {

        async function checkRefreshedSampleAndUpdate() {

            const selectedSamplesInDbResult = await API.get('soap', `/projects/${this.state.projectId}/groups`);

            let chosenSampleLastRefreshInDb =
                selectedSamplesInDbResult.filter(sample => sample.sample_id == sampleId)[0].last_refreshed;

            if(!chosenSampleLastRefreshInDb) {
                setTimeout(checkRefreshedSampleAndUpdate.bind(this, sampleId), 2000);
            } else {

                const clickMoment = moment(clickTime);

                console.log(clickMoment.format());

                if(clickMoment.isBefore(chosenSampleLastRefreshInDb)) {

                    await this.handleFilteringStatsUpdate();

                } else {

                    setTimeout(checkRefreshedSampleAndUpdate.bind(this, sampleId), 2000);

                }
            }
        }

        // checkRefreshedSampleAndUpdate = checkRefreshedSampleAndUpdate.bind(this);

        setTimeout(checkRefreshedSampleAndUpdate.bind(this, sampleId), 2000);
    }

    async handleSampleRefresh(sampleId){

        console.log(sampleId);

        await API.post('soap', '/projects/' + this.props.match.params.id + '/groups', {
            body: {
                "sampleId": sampleId
            }
        });;

        this.pollForRefreshedSample(sampleId, new Date());

    }

    async handleIncentiveDelete(incentive){

        let type = "";

        if(incentive.type == 'filter') {
            type = "filters";
        } else if (incentive.type == 'quota') {
            type = "quotas"
        }

        const results = await API.patch('soap', "/" + type + "/" + incentive.id, {body:
                {points: 0}
        });

        let updatedType = this.state[type];

        updatedType = updatedType.map(type => {
            if(type.id == incentive.id)
            {
                type.points = 0;
            }

            return type;

        });

        this.setState({
            [type]: updatedType
        })
    }

    async handleIncentiveSave(incentive){

        let type = "";

        if(incentive.newIncentiveType == 'filter') {
            type = "filters";
        } else if (incentive.newIncentiveType == 'quota') {
            type = "quotas"
        }

        const result = await API.patch('soap', "/" + type + "/" + incentive.newIncentiveFilterOrQuota, {body:
                {points: incentive.newIncentivePoints}
        });

        if(result.code == 1000) {

            this.setState({errors: {incentives: result.errors}});

            return false;
        } else {

            let updatedType = this.state[type];

            updatedType = updatedType.map(type => {
                if (type.id == incentive.newIncentiveFilterOrQuota) {
                    type.points = incentive.newIncentivePoints;
                }

                return type;

            });

            this.setState({
                [type]: updatedType
            })

            return true;
        }
    }

    async handleSetDefaultIncentive(defaultIncentive){

        const result =  await API.patch('soaptwo', "/projects/" + this.props.match.params.id, {body:
                {default_incentive: defaultIncentive}
        });

        if(result.code == 1000) {

            this.setState({errors: {incentives: result.errors}})

            return false;

        } else {

            this.setState({defaultIncentive: result[0].default_incentive})

            return true;
        }
    }

    async handleProjectClosedToggle() {

        let closedDate = false;

        if(!this.state.projectClosed) {

            closedDate = await new Date().toJSON().slice(0, 10);

        }

        let result = "";

        if(this.props.match.params.id) {
            result = await API.patch('soaptwo', "/projects/" + this.props.match.params.id,
                {body: {
                        closed_date: closedDate
                    }});
        }

        console.log(result);

        this.setState({projectClosed: result[0].closed_date});
    }

    handleSelectQuota(event) {

        const quotaId = parseInt(event.target.value);

        let selectedQuotas = this.state.selectedQuotas;

        if(event.target.checked) {
            selectedQuotas = [...selectedQuotas, quotaId];
        }
        else {
            selectedQuotas = selectedQuotas.filter(selectedQuota =>
                selectedQuota != quotaId
            )
        }

        this.setState({selectedQuotas});
    }

    async handleInviteToggle(event){
        if(event.target.value == 'remind') {
            await this.setState({invite: false})
        } else if(event.target.value == "invite"){
            await this.setState({invite: true})
        }

        this.handleSetQuotaCombinationsAvailableStat();
    }

    async onStatusReminderToggle(event){
        await this.setState({remindStatus: event.target.value})

        this.handleSetQuotaCombinationsAvailableStat()
    }

    handleSetQuotaCombinationsAvailableStat() {

        if(this.state.invite) {
            this.state.quotasTotalAvailableStat = this.state.totalStatusStats.unsent;
        } else {
            if (this.state.remindStatus == 'invited') {
                this.state.quotasTotalAvailableStat = this.state.totalStatusStats.sent;
            } else if (this.state.remindStatus == 'started') {
                this.state.quotasTotalAvailableStat = this.state.totalStatusStats.started;
            } else if (this.state.remindStatus == 'both') {
                this.state.quotasTotalAvailableStat = this.state.totalStatusStats.sent + this.state.totalStatusStats.started;
            }
        }

        let quotaCombinations = this.state.quotaCombinations.map(function(combination){

            if(this.state.invite) {
                combination.available = combination.unsent;
            } else {
                if (this.state.remindStatus == 'invited') {
                    combination.available = combination.sent;
                } else if (this.state.remindStatus == 'started') {
                    combination.available = combination.started;
                } else if (this.state.remindStatus == 'both') {
                    combination.available = combination.sent + combination.started;
                }
            }

            combination.toSend = Math.min(combination.toSend, combination.available);

            return combination;
        }, this);

        this.setState({quotaCombinations});

        this.setTotalToSend();

    }

    async handleSelectQuotas(){

        let selectedQuotas = this.state.selectedQuotas;

        if(selectedQuotas.length == 0) {

            this.setState({quotaCombinations: []})

            return;
        }

        let quotaCombinationsResult = await API.get('soap', '/quota-combinations?ids=' +
            selectedQuotas.join(','));

        console.log(quotaCombinationsResult);

        let quotaCombinations = quotaCombinationsResult.map(function(combination){

            let names = combination.quotaIds.map(function(id){
                return this.state.quotas.filter(quota => quota.id == id)[0].name
            }, this);

            let name = '';

            if(!names.length) {
                name = "Not in any quota"
            } else {
                name = names.join(' | ');
            }

            let target = "-";

            let achieved = "-";

            if(combination.quotaIds.length == 1) {
                target = this.state.quotas.filter(quota => quota.id == combination.quotaIds[0])[0].target;

                achieved = this.state.quotas.filter(quota => quota.id == combination.quotaIds[0])[0].achieved;
            }

            return {
                combinationIndex: combination.combinationIndex,
                quotaIds: combination.quotaIds,
                name: name,
                target: target,
                achieved: achieved,
                unsent: combination.panelistStats.unsent,
                sent: combination.panelistStats.sent,
                started: combination.panelistStats.started,
                definition: combination.definition,
                toSend: 0
            };
        }, this);

        this.setState({quotaCombinations})

        this.handleSetQuotaCombinationsAvailableStat()
    }

    handleToSendChange(event){

        this.setState({distributeToSend: false});

        const toSend = event.target.value;

        const combinationIdString = event.target.name;

        const quotaCombinations = this.state.quotaCombinations.map(function(combination) {

                if(combination.quotaIds.join('#') == combinationIdString) {
                    combination.toSend = Math.min(toSend, combination.available);
                }

                return combination;
            }
        )

        this.setState({quotaCombinations});

        this.setTotalToSend();
    }

    setTotalToSend() {

        let totalToSend = this.state.totalToSend;

        if(this.state.quotaCombinations.length > 1) {

            totalToSend = this.state.quotaCombinations.reduce((accumulator, combination) =>
                accumulator + ((parseInt(combination.toSend) > 0) ? parseInt(combination.toSend) : 0)
                , 0);
        } else {
            totalToSend = Math.min(totalToSend, this.state.quotasTotalAvailableStat)
        }

        this.setState({totalToSend})
    }

    async handleTotalToSendChange(event) {

        await this.setState({totalToSend: event.target.value});

        this.setTotalToSend();

    }

    handleDistribute(event){
        this.handleInputChange(event);

        const distributeType = event.target.value;

        if(this.state.quotaCombinations.length > 0) {

            let quotaCombinations = [];

            quotaCombinations = this.state.quotaCombinations.map(function (combination) {

                if (distributeType === 'xToGet') {
                    combination.toSend = Math.min(this.state.xToGet * (combination.target - combination.achieved),
                        combination.available);
                } else if (distributeType === 'max') {
                    combination.toSend = combination.available;
                } else if (distributeType === 'clear') {
                    combination.toSend = '';
                }
                return combination;
            }, this);


            this.setState([quotaCombinations]);

            this.setTotalToSend();
        }
        else {

            let totalToSend = 0;

            if (distributeType === 'xToGet') {
                totalToSend = Math.min(this.state.xToGet * (this.state.target - this.state.totalCompletes),
                    this.state.quotasTotalAvailableStat);
            } else if (distributeType === 'max') {
                totalToSend = this.state.quotasTotalAvailableStat;
            }

            this.setState({totalToSend});
        }
    }

    async handleSend() {

        if(this.state.sendBlocked) {
            return;
        }

        this.setState({sendBlocked: true});

        let status = '';

        if(this.state.invite) {
            status = 'unsent';
        } else {
            if (this.state.remindStatus == 'invited') {
                status = 'sent';
            } else if (this.state.remindStatus == 'started') {
                status = 'started';
            } else if (this.state.remindStatus == 'both') {
                status = 'both';
            }
        }

        let sendRequestData = {
            total: this.state.totalToSend,
            recipientsStatus: status,
            quotaCombinations: this.state.quotaCombinations,
            templateId: this.state.emailTemplate
        }

        let enqueueResponse = await API.post('soap', '/enqueue-emails/' + this.state.projectId, {
            body: sendRequestData});

        if(enqueueResponse.code > 1) {
            this.setState({invitesRemindersIsError: true, invitesRemindersErrorMessage: enqueueResponse.message});
        }

        await this.handleFilteringStatsUpdate();

        this.setState({sendBlocked: false});
    }

    render() {
        return (
            <div id="view_project_container" className="container">
            <Navigation />
            <h1 id="view_project_title">View Project</h1>
            <hr id="view_project_header_linebreak"></hr>
              <nav id="project_btn_wrapper">
                <Link id="project_btn_1" className="btn btn-primary" to="/">Go to project list</Link>
                <Link id="project_btn_2" className="btn btn-primary" to={"/project-setup/" + this.state.projectId}>Go to project setup</Link>
              </nav>
              <hr></hr>
                <u><h3 id="project_info_header">Project:</h3></u>

                {this.state.projectNumber != "fetching" && (

                    <React.Fragment>
                <p><strong>Job number:</strong> {this.state.projectNumber}</p>
                <p><strong>Job name:</strong> {this.state.projectName}</p>
                <p><strong>Type:</strong> {this.state.projectType}</p>
                <p><strong>Start date:</strong> {this.state.startDate.toLocaleDateString()}</p>

                    </React.Fragment>
                )}


                {/*<b><strong><h2 id="completes_header">Completes</h2></strong></b>*/}
                <u><h3>Current completes:</h3></u>
                <strong><p>Total completes: {this.state.totalCompletes}</p></strong>
                {this.state.sources != "fetching" && (
                    this.state.sources.map(source => <strong><p>{source.name}: {source.completed}</p></strong>)
                )}

                <u><h3 id="target_header">Target completes:</h3></u>
                {this.state.target != "fetching" && (
                    <EditableText
                        currentValue={this.state.target}
                        onSetValue={this.handleSetTarget}
                        error={this.state.errors.target_completes}
                    />
                )}

                <u><h3>Percentage of completes done:</h3></u>

                {this.state.totalCompletes != "fetching" && this.state.target != "fetching" && (
                    <p> { Math.round(this.state.totalCompletes / this.state.target * 100) }% </p>
                )}

                <b><h3>Quotas</h3></b>
                <Quotas
                    project={this.state}
                    onChangeStatus={this.handleQuotaStatusChange}
                    onDelete={this.handleQuotaDelete}
                    onSave={this.handleQuotaSave}
                    onEdit={this.handleQuotaEdit}
                    onRecalculate={this.handleFilteringStatsUpdate}
                    errors={this.state.errors.quota ? this.state.errors.quota : {}}
                />
                <br></br>
                <br></br>
                <br></br>
                <Table className="main_table">
                    <thead>
                        <tr>
                            <th>Source</th>
                            <th>#Sent</th>
                            <th>#Started</th>
                            <th>#Screen outs</th>
                            <th>#Quota full</th>
                            <th>#Quality Fail</th>
                            <th>#Completed</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Total</td>
                        <td>{this.state.totalSent}</td>
                        <td>{this.state.totalStarted}</td>
                        <td>{this.state.totalScreenOuts}</td>
                        <td>{this.state.totalQuoataFull}</td>
                        <td>{this.state.totalQualityFail}</td>
                        <td>{this.state.totalCompletes}</td>
                    </tr>
                    {this.state.sources != "fetching" && (
                        this.state.sources.map(source =>
                        <tr>
                            <td>{source.name}</td>
                            <td>{source.sent}</td>
                            <td>{source.started}</td>
                            <td>{source.screenout}</td>
                            <td>{source.quota_full}</td>
                            <td>{source.quality_fail}</td>
                            <td>{source.completed}</td>
                        </tr>
                        )
                    )}
                    </tbody>
                </Table>

                {this.state.sources != "fetching" && (
                    this.state.sources.filter(source => source.id).map(source =>
                        <EditSentCount
                            projectId={this.state.projectId}
                            sourceName={source.name}
                            sourceId={source.id}
                            sendCount={source.sent}
                            onSourceSentUpdate={this.sourcesSentRefresh}
                            error={false}
                        />
                    )
                )}

                <Table className="main_table">
                    <thead>
                        <tr>
                            <th>%Start Rate</th>
                            <th>%Response Rate</th>
                            <th>%Completion Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {Math.round(
                                    (this.state.totalStarted + this.state.totalCompletes + this.state.totalScreenOuts
                                        + this.state.totalQuoataFull + this.state.totalQualityFail)
                                        /
                                        (this.state.totalSent + this.state.totalStarted + this.state.totalCompletes
                                        + this.state.totalScreenOuts + this.state.totalQuoataFull
                                        + this.state.totalQualityFail)
                                    * 100
                                )}
                            </td>
                            <td>
                                {Math.round(
                                    (this.state.totalCompletes + this.state.totalScreenOuts
                                        + this.state.totalQuoataFull + this.state.totalQualityFail)
                                    /
                                    (this.state.totalSent + this.state.totalStarted + this.state.totalCompletes
                                        + this.state.totalScreenOuts + this.state.totalQuoataFull
                                        + this.state.totalQualityFail)
                                    * 100)}
                            </td>
                            <td>{Math.round(
                                (this.state.totalCompletes)
                                /
                                (this.state.totalStarted + this.state.totalCompletes
                                    + this.state.totalScreenOuts + this.state.totalQuoataFull
                                    + this.state.totalQualityFail)
                                * 100)}</td>
                        </tr>
                    </tbody>
                </Table>

                <br></br>
                <br></br>

                {this.state.client_survey_url !="fetching" && (
                <Form.Group controlId="client_survey_url">
                    <ErrorDisplayingFormField
                        label="Client Survey Url"
                        type="text"
                        name="client_survey_url"
                        value={this.state.client_survey_url}
                        onChange={this.handleInputChange}
                        errors={this.state.errors}
                    />
                    <Button
                        id="view_projects_save_btn_1"
                        variant="primary"
                        onClick={this.handleClientSurveyUrlSave}
                    >Save</Button>
                </Form.Group>
                )}

                <hr></hr>

                {this.state.clientIdParamName !="fetching" && (
                <Form.Group controlId="client_id_param_name">
                    <ErrorDisplayingFormField
                        label="Client Id Param Name"
                        type="text"
                        name="clientIdParamName"
                        value={this.state.clientIdParamName}
                        onChange={this.handleInputChange}
                        errors={this.state.errors}
                    />

                    <Button
                        id="view_projects_save_btn_2"
                        variant="primary"
                        onClick={this.handleClientIdParamNameSave}
                    >Save</Button>
                </Form.Group>
                )}
                <br></br>
                <br></br>
              <div id="expandable_containers">
                <Expandable
                title="YouthSight URLs for Client"
                >
                    {this.state.projectNumber != "fetching" && (

                        <React.Fragment>
                    <ParameterSelector
                        title="Unique Identifier Param"
                        paramType="uniqueidentifier"
                        defaultParam="yscode"
                        paramFor="client"
                        value={this.state.clientUniqueIdentifierParam.id}
                        onSave={this.handleParamSave}
                    />

                    <ParameterSelector
                        title="Result Code Param"
                        paramType="resultcode"
                        paramFor="client"
                        value={this.state.clientResultCodeParam.id}
                        onSave={this.handleParamSave}
                    />

                    <YouthsightUrlForClient
                        project={this.state}
                        status="completion"
                        resultCode={this.state.completionCode}
                    />

                    <YouthsightUrlForClient
                        project={this.state}
                        status="screen-out"
                        resultCode="2"
                    />

                    <YouthsightUrlForClient
                        project={this.state}
                        status="quota full"
                        resultCode="3"
                    />

                    <YouthsightUrlForClient
                        project={this.state}
                        status="rejected"
                        resultCode="4"
                    />
                        </React.Fragment>
                        )}

                </Expandable>

                <Expandable title="Panel Variables">
                    <PanelVariables
                    selectedPanelVariables={this.state.selectedPanelVariables}
                    onAddVariable={this.handleAddPanelVariable}
                    />
                </Expandable>

                <Expandable title="Filters">
                    {this.state.filters != "fetching" && (
                    <Filters
                        filters={this.state.filters}
                        lastRecalculated={this.state.filtersLastRecalculated}
                        projectId={this.state.projectId}
                        availablePanelVariables={this.state.selectedPanelVariables}
                        onStatusChange={this.handleFilterStatusChange}
                        onDelete={this.handleFilterDelete}
                        onSave={this.handleFilterSave}
                        errors={this.state.errors.filter ? this.state.errors.filter : {}}
                        onRecalculate={this.handleFiltersRecalculate}
                    />
                    )}
                </Expandable>

                <Expandable title="Samples">
                    {this.state.selectedSamples != "fetching" && (
                    <Samples
                        project={this.state}
                        onSamplesSearch={this.handleSamplesSearch}
                        onAddSample={this.handleAddSample}
                        onRefresh={this.handleSampleRefresh}
                    />
                    )}
                </Expandable>

                <Expandable title="Incentives">
                    {this.state.filters != "fetching" && this.state.quotas != "fetching" && (
                    <Incentives
                        project={this.state}
                        onDelete={this.handleIncentiveDelete}
                        onSave={this.handleIncentiveSave}
                        onSetDefaultIncentive={this.handleSetDefaultIncentive}
                        errors={this.state.errors.incentives ? this.state.errors.incentives : {}}
                    />
                    )}
                </Expandable>

                <Expandable title="Test URLS">
                    <br></br>
                    {this.state.projectNumber != "fetching" && (
                    <TestUrls
                        project={this.state}
                    />
                    )}
                </Expandable>


              {!this.state.projectClosed && (
                <Expandable title="Emailing">
                    <Form.Group>
                    <br></br>
                        {this.state.emailTemplates != "fetching" && (
                            <React.Fragment>
                                <Form.Label>
                                    Email Template
                                </Form.Label>

                                <p>
                                    <Link className="btn btn-primary admin-button" to={"/mail-template-management/"}>Mail Templates</Link>
                                </p>

                            <Form.Control
                                as="select"
                                name='emailTemplate'
                                value={this.state.emailTemplate}
                                onChange={this.handleInputChange}
                            >
                                {this.state.emailTemplates.map(template =>
                                <option value={template.id}>{template.name}</option>
                                )}
                            </Form.Control>
                            </React.Fragment>
                        )}
                    </Form.Group>

                    <br></br>

                    {this.state.users != "fetching" && (
                    <Expandable title="Testers">
                        <Testers
                            project={this.state}
                        />
                    </Expandable>
                    )}

                    <br></br>

                    {this.state.quotas != "fetching" && this.state.totalStatusStats != "fetching" && (
                        <InvitesReminders
                            project={this.state}
                            selectedQuotas={this.state.selectedQuotas}
                            totalStatusStats={this.state.totalStatusStats}
                            quotaCombinations={this.state.quotaCombinations}
                            invite={this.state.invite}
                            remindStatus={this.state.remindStatus}
                            totalToSend={this.state.totalToSend}
                            quotasTotalAvailableStat={this.state.quotasTotalAvailableStat}
                            xToGet={this.state.xToGet}
                            onSetTotalToSend={this.setTotalToSend}
                            onSelectQuota={this.handleSelectQuota}
                            onInviteToggle={this.handleInviteToggle}
                            onStatusReminderToggle={this.onStatusReminderToggle}
                            onSelectQuotas={this.handleSelectQuotas}
                            onTotalToSendChange={this.handleTotalToSendChange}
                            onToSendChange={this.handleToSendChange}
                            onDistribute={this.handleDistribute}
                            onSetQuotaCombinationsAvailableStat={this.handleSetQuotaCombinationsAvailableStat}
                            onSend={this.handleSend}
                            isError={this.state.invitesRemindersIsError}
                            errorMessage={this.state.invitesRemindersErrorMessage}
                        />
                    )}
                </Expandable>
              )}

                {this.state.sources != "fetching" && this.state.sources.length > 1 && (

                    <React.Fragment>

                        <Expandable title="YouthSight URLs for supplier">
                            <YouthsightUrlsForSupplier
                                project={this.state}
                                onSave={this.handleParamSave}
                            />
                        </Expandable>

                        <Expandable title="Supplier URLs for YouthSight">
                            <SupplierUrlsForYouthsight
                                project={this.state}
                            />
                        </Expandable>
                    </React.Fragment>
                )}
                  </div>
                {this.state.projectClosed != "fetching" && (this.state.projectClosed ? (
                    <Button id="reopen_project_btn" variant="primary"
                            onClick={this.handleProjectClosedToggle}
                    >Re-open project</Button>
                ) : (
                    <Button id="close_project_btn" variant="primary"
                            onClick={this.handleProjectClosedToggle}
                    >Close project</Button>
                ))
                }

                {this.state.projectClosed && this.state.projectClosed != "fetching" && (
                    <p>Project Closed: {new Date(this.state.projectClosed).toLocaleDateString('en-GB')}</p>
                )}

                <h3>Completion stats</h3>
                <CompletionStatsTable
                    projectId={this.state.projectId}
                    interval="week"
                />

                <CompletionStatsTable
                    projectId={this.state.projectId}
                    interval="month"
                />

            </div>
        )
    }
}

export default viewProject;
