import React, {Component} from 'react';
import {Button, Form, FormControl, Table} from "react-bootstrap";
import Filter from "./filter";
import Faker from "faker";
import {API} from 'aws-amplify';
import FilterDefinition from "./filterDefinition";

class Filters extends Component{

    constructor(props){
        super(props);

        this.handleToggleNewFilter = this.handleToggleNewFilter.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

        this.handleDefinitionChange = this.handleDefinitionChange.bind(this);
    }

    handleToggleNewFilter() {
        this.setState({newFilterOpen: !this.state.newFilterOpen});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleClickSave(){

        const setSucessfully = await this.props.onSave(this.state);

        if(setSucessfully) {
            this.handleToggleNewFilter();
        }
    }

    handleEdit(editingFilter){

        console.log(editingFilter);

        this.setState({
            newFilterName: editingFilter.name,
            newFilterDefinition: editingFilter.definition,
            newFilterTarget: editingFilter.target,
            newFilterOpen: true
        })
    }

    handleDefinitionChange(newFilterDefinition){
        this.setState({newFilterDefinition: newFilterDefinition})
    }



    state = {
        filters: "fetching",
        newFilterOpen: false,
        newFilterName: null,
        newFilterDefinition: '',
        newFilterTarget: null
    }


    render() {
        return (
            <React.Fragment>
                <Table className="filters-table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Definition</th>
                        <th>In filter</th>
                        <th>Filtered Out</th>
                        <th>Status</th>
                        <th className="nohov_btn" colSpan="2"></th>
                    </tr>
                    </thead>
                    {this.props.filters != 'fetching' && this.props.filters.map(filter =>
                        <Filter
                            key={filter.id}
                            filter={filter}
                            onChangeStatus={this.props.onStatusChange}
                            onDelete={this.props.onDelete}
                            onSave={this.props.onSave}
                            onEdit={this.handleEdit}
                        />
                    )}
                </Table>


                {this.state.newFilterOpen && (

                    <div>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="newFilterName"
                                onChange={this.handleInputChange}
                                value={this.state.newFilterName}
                                isInvalid={this.props.errors.name}
                            />
                            {this.props.errors.name && (
                                <FormControl.Feedback
                                    type="invalid"
                                >{this.props.errors.name.message}</FormControl.Feedback>
                            )}
                        </Form.Group>

                        <FilterDefinition
                            newFilterDefinition={this.state.newFilterDefinition}
                            onDefinitionChange={this.handleDefinitionChange}
                            availablePanelVariables={this.props.availablePanelVariables}
                            errors={this.props.errors.definition}
                        />


                        <Button
                            variant="primary"
                            onClick={this.handleClickSave}
                        >Save</Button>
                        <Button
                            variant="secondary"
                            onClick={this.handleToggleNewFilter}
                        >Cancel</Button>
                    </div>
                    )
                    }

                    {!this.state.newFilterOpen && (
                        <React.Fragment>
                            <Button
                                variant="primary"
                                onClick={this.handleToggleNewFilter}
                            >New</Button>

                            {this.props.lastRecalculated && (
                                <React.Fragment>
                                    <Button
                                    variant="secondary"
                                    onClick={this.props.onRecalculate}
                                    >Recalculate</Button>
                                    <span>Last recalculated:&nbsp;
                                        {this.props.lastRecalculated != "fetching" && (
                                            this.props.lastRecalculated.toLocaleString('en-GB')
                                        )}
                                    </span>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}

                    </React.Fragment>
                    )
                    }
}

export default Filters;
