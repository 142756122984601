import React, {Component} from "react";
import {Form, FormControl} from 'react-bootstrap';

class ErrorDisplayingFormField extends Component {

    render() {
        return (
            <Form.Group>
                <Form.Label>{this.props.label}</Form.Label>
                    <Form.Control
                        id={this.props.id}
                        type={this.props.type}
                        name={this.props.name}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        isInvalid={this.props.errors[this.props.name]}
                    />

                {this.props.errors[this.props.name] && (
                    <FormControl.Feedback
                        type="invalid"
                    >{this.props.label + " " + this.props.errors[this.props.name].message}</FormControl.Feedback>
                )}

            </Form.Group>
        )
    }

}

export default ErrorDisplayingFormField;