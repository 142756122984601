import React, {Component} from 'react';
import ArchivedProjects from './archivedProjects.jsx';
import Navigation from "./navigation";

class ArchivedProjectList extends Component {

    render(){
        return (

            <React.Fragment>

                <div className="container">

                    <Navigation/>

                    <h1>Archived Projects</h1>

                    <ArchivedProjects />

                </div>

            </React.Fragment>
        )
    }

}

export default ArchivedProjectList;