import React, {Component} from 'react';
import Faker from 'faker';
import {Table, Form, Button, FormControl} from 'react-bootstrap';
import User from "./user.jsx";
import {API} from "aws-amplify";

class Users extends Component {

    constructor(props) {
        super(props);

        this.handleToggleNewUser = this.handleToggleNewUser.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);

        this.state = {
            users: "fetching",
            newUserOpen: false,
            editingUser: false,
            errors: false
        }
    }

    handleToggleNewUser() {
        this.setState({
            newUserOpen: !this.state.newUserOpen,
            editingUser: false
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSave(){

        let userResponse;

        if(this.state.editingUser) {

            userResponse = await API.patch('soapthree', '/users', {
                'body': {
                    email: this.state.newUserEmail,
                    username: this.state.newUserName,
                    password: this.state.newUserPassword
                }
            });

        } else {

            userResponse = await API.post('soapthree', '/users', {
                'body': {
                    email: this.state.newUserEmail,
                    username: this.state.newUserName,
                    password: this.state.newUserPassword
                }
            });
        }

        if(userResponse.code == 1000) {

            this.setState({
                newUserPassword: '',
                errors: userResponse.errors
            });

        } else {

            const users = await API.get('soapthree', '/users');

            this.setState({users});

            this.handleToggleNewUser();
        }
    }

    handleEdit(editingUser){

        this.setState({
            newUserName: editingUser.name,
            newUserEmail: editingUser.email,
            newUserOpen: true,
            editingUser: true
        })
    }

    async handleDelete(event){

        const username = event.target.value;

        await API.del('soapthree', '/users/' + username);

        const users = this.state.users.filter(user => {
            return user.username != username;
        });

        this.setState({users});
    }

    async componentDidMount() {

        let usersResult = await API.get('soapthree', '/users');

        let statsResult = await API.get('soapthree', '/users-stats');

        let users = usersResult.map(function(user){

            let matchingStats = this.statsResult.filter(stats => stats.username == user.username)[0];

            if(matchingStats) {
                user.last_login = matchingStats.last_login;
                user.last_ip = matchingStats.last_ip;
            }

            return user;
        },{statsResult: statsResult} )

        this.setState({users})
    }

    render() {
        return (
            <React.Fragment>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Last Login Date</th>
                            <th>Last Login IP</th>
                            <th colSpan="2"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users != "fetching" && this.state.users.map(user =>
                            <User
                                key={user.id}
                                user={user}
                                onDelete={this.handleDelete}
                                onEdit={this.handleEdit}
                            />
                        )}
                    </tbody>
                </Table>

                {this.state.newUserOpen && (
                    <div>
                        {!this.state.editingUser && (
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="newUserName"
                            onChange={this.handleInputChange}
                            value={this.state.newUserName}
                        />
                    </Form.Group>
                        )}

                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            name="newUserEmail"
                            onChange={this.handleInputChange}
                            value={this.state.newUserEmail}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="newUserPassword"
                            onChange={this.handleInputChange}
                            value={this.state.newUserPassword}
                            isInvalid={this.state.errors && this.state.errors.newUserPassword}
                        />
                        {this.state.errors && this.state.errors.newUserPassword && (
                            <FormControl.Feedback
                                type="invalid"
                            >{this.state.errors.newUserPassword.message}</FormControl.Feedback>
                        )}
                    </Form.Group>

                        <Button
                            variant="primary"
                            onClick={this.handleSave}
                        >Save</Button>
                        <Button
                            variant="secondary"
                            onClick={this.handleToggleNewUser}
                        >Cancel</Button>
                    </div>
                )}

                {!this.state.newUserOpen && (
                    <Button className="admin-table-btns"
                        variant="primary"
                        onClick={this.handleToggleNewUser}
                    >New</Button>
                )}
            </React.Fragment>
        )
    }
}

export default Users;
