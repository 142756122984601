import React, {Component} from 'react';
import {Form, Button, FormControl} from 'react-bootstrap';
import ExternalSamples from './externalSamples.jsx'
import { BrowserRouter as Router} from "react-router-dom";
import SelectProjectClient from './selectProjectClient';
import {Link} from 'react-router-dom';
import {API, Auth} from 'aws-amplify';
import ErrorDisplayingFormField from './errorDisplayingFormField';
import ErrorDisplayingSelect from "./errorDisplayingSelect";
import Navigation from "./navigation";

class setupProject extends Component {




    state = {
        usingExternalSample: false,
        mobile_compatability: false,
        screen_out_probability: 0,
        externalSampleSuppliers: "fetching",
        selectedExternalSampleSuppliers: [],
        client_id: 35,
        errors: {},
        start_date: new Date().toISOString().split('T')[0]
    }

    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddExternalSample = this.handleAddExternalSample.bind(this);
        this.handleChangeExternalSampleSend = this.handleChangeExternalSampleSend.bind(this);
        this.handleDeleteExternalSample = this.handleDeleteExternalSample.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSelectClient = this.handleSelectClient.bind(this);
    }

    async componentDidMount() {


        let result = await API.get('soaptwo', '/projects/' + this.props.match.params.id);

        if(result[0]) {
            result[0]['start_date'] = result[0]['start_date'].split('T')[0];
            result[0]['end_date'] = result[0]['end_date'].split('T')[0];

            this.setState(result[0]);

            result = await API.get('soaptwo', '/projects/' + this.props.match.params.id + '/suppliers')

            result = result.filter(result => result.supplier_id);

            this.setState({selectedExternalSampleSuppliers: result});

            if(result.length) {
                this.setState({usingExternalSample: true})
            }
        }

        const externalSampleSuppliers = await API.get('soaptwo', '/suppliers');

        externalSampleSuppliers.map(supplier => {
            if(this.state.selectedExternalSampleSuppliers.map(supplier => supplier.id).includes(supplier.id)) {

                supplier.selected = true;

                return supplier;
            }
        });

        this.setState({externalSampleSuppliers});
    }

    handleSelectClient(event) {
        const clientId = event.target.value;

        this.setState({client_id: clientId});
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleAddExternalSample(supplierId) {

        if(supplierId > 0) {
            const selectedExternalSampleSuppliers = [...this.state.selectedExternalSampleSuppliers,
                {
                    id: supplierId,
                    sends: 0
                }];

            this.setState({selectedExternalSampleSuppliers});

            let externalSampleSuppliers = JSON.parse(JSON.stringify(this.state.externalSampleSuppliers));

            externalSampleSuppliers.forEach(supplier => {
                if(supplier.id == supplierId) {
                    supplier.selected = true;
                }
            });

            this.setState({externalSampleSuppliers});
        }
    }

    handleChangeExternalSampleSend(supplierId, sends) {

        let selectedExternalSampleSuppliers = this.state.selectedExternalSampleSuppliers;

        selectedExternalSampleSuppliers = selectedExternalSampleSuppliers.map(function(supplier){
            if(supplier.id == supplierId) {
                supplier.sent = sends;
            }

            return supplier;
        })

        this.setState({selectedExternalSampleSuppliers});
    }

    handleDeleteExternalSample(supplierId) {

        const selectedExternalSampleSuppliers = this.state.selectedExternalSampleSuppliers.filter(
            supplier => supplier.id !== supplierId);

        this.setState({selectedExternalSampleSuppliers});

        let externalSampleSuppliers = JSON.parse(JSON.stringify(this.state.externalSampleSuppliers));

        externalSampleSuppliers.forEach(supplier => {
            if(supplier.id == supplierId) {
                supplier.selected = false;
            }
        });

        this.setState({externalSampleSuppliers});
    }

    handleCancel(){
        this.props.history.goBack();
    }

    async handleSave(destination){

        let projectId = this.props.match.params.id ? this.props.match.params.id : "";

        const userInfo = await Auth.currentUserInfo();

        const callBody = {
            job_number: this.state.job_number,
            name: this.state.name,
            type: this.state.type,
            client_id: this.state.client_id + "",
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            target_completes: this.state.target_completes + "",
            landing_page_text: this.state.landing_page_text,
            survey_length: this.state.survey_length + "",
            expected_incidence_rate: this.state.expected_incidence_rate + "",
            mobile_compatability: this.state.mobile_compatability,
            screen_out_probability: this.state.screen_out_probability + "",
            creator: userInfo.username
        };

        let result = "";

        if(this.props.match.params.id) {
            result = await API.patch('soaptwo', "/projects/" + projectId, {body: callBody});
        }
        else {
            result = await API.post('soaptwo', "/projects/" + projectId, {body: callBody});
        }

        let isErrors = false;

        if(result.code == 1000) {

            isErrors = true;

            this.setState({errors: result.errors})
        } else {

            projectId = result[0].id;

            if (this.state.selectedExternalSampleSuppliers.length) {

                result = await API.patch('soaptwo', '/projects/' + projectId + "/suppliers", {
                    body:
                        {
                            suppliers: this.state.selectedExternalSampleSuppliers.map(
                                supplier => {return {id: supplier.id, sends: supplier.sent}})
                        }
                })

                if(result.code == 1000) {
                    isErrors = true;
                    this.setState({errors: result.errors})
                }

            }
        }

        if(!isErrors)  {
            if (destination === "list") {
                this.props.history.push('/');
            } else if (destination == "view") {
                this.props.history.push('/project-view/' + projectId);
            }
        }
    }


    render() {
        return (
        <div id="setup_container" className="container clearfix">
        <Navigation />

              <h1>New Project</h1>

              <hr></hr>
              <br></br>
              <Form>
                  <ErrorDisplayingFormField
                      type="text"
                      name="job_number"
                      onChange={this.handleInputChange}
                      value={this.state.job_number}
                      errors={this.state.errors}
                      label="Job Number"
                  />

                  <ErrorDisplayingFormField
                      type="text"
                      name="name"
                      onChange={this.handleInputChange}
                      value={this.state.name}
                      errors={this.state.errors}
                      label="Project Name"
                  />

                  <ErrorDisplayingSelect
                      type="text"
                      name="type"
                      onChange={this.handleInputChange}
                      value={this.state.type}
                      options={
                          [
                              {label:'Online survey', value: 'Online survey'},
                              {label:'Online community', value: 'Online community'},
                              {label:'Video survey', value: 'Video survey'},
                              {label:'Recruitment for face to face interview',
                                  value: 'Recruitment for face to face interview'},
                              {label:'Recruitment for phone interview', value: 'Recruitment for phone interview'},
                              {label:'Recruitment for online interview', value: 'Recruitment for online interview'},
                              {label:'Online survey with video questions', value: 'Online survey with video questions'},
                              {label:'Learn more about what you do online - MC+',
                                  value: 'Learn more about what you do online - MC+'},
                              {label:'Learn more about what you do online - Ipsos',
                                  value: 'Learn more about what you do online - Ipsos'},
                          ]
                      }
                      errors={this.state.errors}
                      label="Project Type"
                  />

                  <SelectProjectClient
                      clientId={this.state.client_id}
                      onSelect={this.handleSelectClient}
                  />

                  <ErrorDisplayingFormField
                      type="date"
                      name="start_date"
                      onChange={this.handleInputChange}
                      value={this.state.start_date}
                      errors={this.state.errors}
                      label="Start Date"
                  />

                  <ErrorDisplayingFormField
                      type="date"
                      name="end_date"
                      onChange={this.handleInputChange}
                      value={this.state.end_date}
                      errors={this.state.errors}
                      label="End Date"
                  />

                  <ErrorDisplayingFormField
                      type="text"
                      onChange={this.handleInputChange}
                      name="target_completes"
                      value={this.state.target_completes}
                      errors={this.state.errors}
                      label="Target Completes"
                  />

                  <ErrorDisplayingFormField
                      type="text"
                      name="survey_length"
                      value={this.state.survey_length}
                      onChange={this.handleInputChange}
                      errors={this.state.errors}
                      label="LOI (Minutes)"
                  />

                  <ErrorDisplayingFormField
                      type="text"
                      onChange={this.handleInputChange}
                      name="expected_incidence_rate"
                      value={this.state.expected_incidence_rate}
                      errors={this.state.errors}
                      label="Expected IR (%)"
                  />

                  <Form.Check
                      type="checkbox"
                      id="mobileCompatability"
                      name="mobile_compatability"
                      label="Mobile Compatability"
                      checked={this.state.mobile_compatability}
                      onChange={this.handleInputChange}
                  />

                  <Form.Group controlId="screenout_probability">
                      <Form.Label>Screen-out Probability</Form.Label>
                      <Form.Control as="select"
                                    onChange={this.handleInputChange}
                                    name="screen_out_probability"
                                    value = {this.state.screen_out_probability}
                      >
                          <option value="0">None</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                      </Form.Control>
                  </Form.Group>

                  <Form.Check
                      type="checkbox"
                      id="usingExternalSample"
                      name="usingExternalSample"
                      label="External Sample"
                      checked={this.state.usingExternalSample}
                      onChange={this.handleInputChange}
                  />

                  <br></br>
                  {this.state.usingExternalSample && this.state.externalSampleSuppliers != "fetching" &&
                  (<ExternalSamples
                      externalSampleSuppliers={this.state.externalSampleSuppliers}
                      selectedExternalSampleSuppliers={this.state.selectedExternalSampleSuppliers}
                      onAddExternalSample={this.handleAddExternalSample}
                      onChangeExternalSampleSend={this.handleChangeExternalSampleSend}
                      onDeleteExternalSample={this.handleDeleteExternalSample}
                      errors={this.state.errors}
                  ></ExternalSamples>)
                  }

                  <Form.Group controlId="landing_page_text">
                    <Form.Label>Landing Page Text</Form.Label>
                      <Form.Control
                          as="textarea"
                          rows="5"
                          name="landing_page_text"
                          value={this.state.landing_page_text}
                          onChange={this.handleInputChange}
                      />
                    </Form.Group>

                    <br></br>

                  <div id="setup_btns_containers">
                    <Button className="setupbtns" variant="primary" onClick={() => this.handleSave('list')}>Save and return to project list</Button>
                    <Button className="setupbtns" variant="primary" onClick={() => this.handleSave('view')}>Save and open project view</Button>
                    <hr></hr>
                    <Button id="cancel_setup" variant="secondary" onClick={this.handleCancel}>Cancel</Button>
                  </div>

                </Form>

            </div>
        )
    }
}

export default  setupProject;
