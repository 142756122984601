import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';

class YouthsightUrlForClient extends Component {

    copyToClipboard = (e) => {

        this.input.select();
        document.execCommand('copy');
        e.target.focus();
    }

    render(){
        return (

                <Form.Group>
                    <Form.Label>Survey URL for {this.props.status}:</Form.Label>
                <Form.Control
                    type="text"
                    value={window.location.origin.toString() + '/landing?' +
                    (this.props.project.clientResultCodeParam.name
                        ? this.props.project.clientResultCodeParam.name
                        : "result")
                    + '=' + this.props.resultCode +
                    '&' +
                    (this.props.project.clientUniqueIdentifierParam.name
                    ? this.props.project.clientUniqueIdentifierParam.name
                    : 'yscode')
                    + '='}
                    ref={(input) => this.input = input}
                />
                <Button className="copy_btn"
                    variant="primary"
                    onClick={this.copyToClipboard}
                >Copy to clipboard</Button>
                    <p>Tracking Pixel</p>
                    <p>
                        {"<img src=\"https://d3vd813279.execute-api.us-east-1.amazonaws.com/test/pixel?" +
                        (this.props.project.clientResultCodeParam.name
                            ? this.props.project.clientResultCodeParam.name
                            : "result")
                        + '=' + this.props.resultCode +
                        '&' +
                        (this.props.project.clientUniqueIdentifierParam.name
                            ? this.props.project.clientUniqueIdentifierParam.name
                            : 'yscode')
                        + '=' +
                        "\"/>"}
                    </p>
                </Form.Group>
        )
    }
}

export default YouthsightUrlForClient;
