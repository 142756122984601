import React, {Component} from 'react';
import {API} from "aws-amplify";
import {isMobile} from 'react-device-detect';

const queryString = require('query-string');


class Landing extends Component {

    constructor(props) {
        super(props);

        this.handleClickLink = this.handleClickLink.bind(this);
    }

    state = {};

    async handleClickLink() {

        const statusResult = await API.get('return', '/handleLanding?yscode='
            + encodeURIComponent(this.state.yscode) + "&" + this.state.resultCodeParam + "=1");

        let surveyLink = "http://" + this.state.url.replace(/https?:\/\//gi, '');

        window.location = surveyLink;

    }

    async componentDidMount() {

        console.log(this.props.location.search);

        const landingResult = await API.get('return', '/handleLanding' + this.props.location.search);

        if(landingResult.redirect) {

            let redirect = "http://" + landingResult.redirect.replace(/https?:\/\//gi, '');

            window.location = redirect;
        } else {

            this.setState({
                content: landingResult.content,
                url: landingResult.url,
                yscode: landingResult.yscode,
                resultCodeParam: landingResult.resultCodeParam,
                mobileCompatibility: landingResult.mobileCompatibility
            })
        }
    }

    render() {
        return (

            <React.Fragment>

                {this.state.content && (
                <div dangerouslySetInnerHTML={{__html: this.state.content}}></div>
                )}

                {this.state.url && (this.state.mobileCompatibility || !isMobile) && (
                <a class="btn btn-primary enter-survey"
                onClick={this.handleClickLink}
                >Click here to go to survey</a>
                )}

                {this.state.content && !this.state.mobileCompatibility && isMobile && (
                    <div>This survey cannot be completed on mobiles. Please us a laptop or desktop computer to
                    access this survey link</div>
                )}
            </React.Fragment>
        )
    }
}

export default Landing;