import React, {Component} from 'react';
import {Table, Button, Form, FormControl} from 'react-bootstrap';
import Quota from "./quota";
import FilterDefinition from "./filterDefinition";

class Quotas extends Component {

    constructor(props){
    super(props);

    this.handleToggleNewQuota = this.handleToggleNewQuota.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

    this.handleDefinitionChange = this.handleDefinitionChange.bind(this);

    }

    handleToggleNewQuota() {
        this.setState({newQuotaOpen: !this.state.newQuotaOpen});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleDefinitionChange(newDefinition){
        this.setState({newQuotaDefinition: newDefinition});
    }

    async handleClickSave(){

        const setSucessfully = await this.props.onSave(this.state);

        if(setSucessfully) {
            this.handleToggleNewQuota();
        }
    }

    state = {
        newQuotaOpen: false,
        newQuotaName: null,
        newQuotaDefinition: "",
        newQuotaTarget: null
    }

    render() {
        return (
            <React.Fragment>
                <Table className="white">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Definition</th>
                            <th>Target</th>
                            <th>Achieved</th>
                            <th>To Get</th>
                            <th>Available</th>
                            <th>Status</th>
                            <th className="nohov_btn" colSpan="2"></th>
                        </tr>
                    </thead>
                {this.props.project.quotas != "fetching" && this.props.project.quotas.map(quota =>
                    <Quota
                        key={quota.id}
                        quota={quota}
                        onChangeStatus={this.props.onChangeStatus}
                        onDelete={this.props.onDelete}
                        onSave={this.props.onEdit}
                        onEdit={this.props.onEdit}
                    />
                    )}
                </Table>

                <hr></hr>
                {this.state.newQuotaOpen && (

                    <div>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="newQuotaName"
                            onChange={this.handleInputChange}
                            value={this.state.newQuotaName}
                            isInvalid={this.props.errors.name}
                        />
                        {this.props.errors.name && (
                            <FormControl.Feedback
                                type="invalid"
                            >{this.props.errors.name.message}</FormControl.Feedback>
                        )}
                    </Form.Group>

                    <FilterDefinition
                        newFilterDefinition={this.state.newQuotaDefinition}
                        onDefinitionChange={this.handleDefinitionChange}
                        availablePanelVariables={this.props.project.selectedPanelVariables}
                        errors={this.props.errors.definition}
                    />

                    <Form.Group>
                    <Form.Label>Target</Form.Label>
                    <Form.Control
                        type="text"
                        name="newQuotaTarget"
                        onChange={this.handleInputChange}
                        value={this.state.newQuotaTarget}
                        isInvalid={this.props.errors.target}
                    />
                        {this.props.errors.target && (
                            <FormControl.Feedback
                                type="invalid"
                            >{this.props.errors.target.message}</FormControl.Feedback>
                        )}
                    </Form.Group>

                        <Button
                            variant="primary"
                            onClick={this.handleClickSave}
                        >Save</Button>
                        <Button
                            variant="secondary"
                            onClick={this.handleToggleNewQuota}
                        >Cancel</Button>
                    </div>
                    )
                }

                {!this.state.newQuotaOpen && (
                <Button
                    variant="primary"
                    onClick={this.handleToggleNewQuota}
                >New</Button>
                )}

                <Button
                    variant="secondary"
                    onClick={this.props.onRecalculate}
                    >Recalculate</Button>

                <span>Last recalculated:&nbsp;
                    {this.props.project.quotasLastRecalculated != "fetching" && (
                        this.props.project.quotasLastRecalculated.toLocaleString('en-GB')
                    )}
                </span>

            </React.Fragment>
        )
    }
}

export default Quotas;
