import React, {Component} from "react";
import {Button, Form, FormControl} from "react-bootstrap";
import {API} from "aws-amplify";

class EditSentCount extends Component{

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickSet = this.handleClickSet.bind(this);
    }

    state = {
        sendCount: 0,
        error: false
    }

    componentDidMount() {

        this.setState({sendCount: this.props.sendCount});

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            error: false
        });
    }

    async handleClickSet() {

        let updateSendsResult = await API.patch('soaptwo',
            `/projects/${this.props.projectId}/suppliers`,
            {body: {
                    preserveAbsent: true,
                    suppliers: [{id: this.props.sourceId, sends: this.state.sendCount}]
                }});

        if(updateSendsResult.code == 1000) {
            this.setState({error: updateSendsResult.errors[Object.keys(updateSendsResult.errors)[0]]})
        } else {
            this.props.onSourceSentUpdate();
        }
    }

    render() {
        return (
            <div>
                <p>Update {this.props.sourceName} sent count</p>
                <Form.Group>
                    <Form.Control
                        onChange={this.handleInputChange}
                        type="text"
                        name="sendCount"
                        value={this.state.sendCount}
                        isInvalid={this.state.error}
                    />

                    {this.state.error && (
                        <FormControl.Feedback
                            type="invalid"
                        >{this.state.error.message}</FormControl.Feedback>
                    )}


                    <Button id="edit_set_btn" onClick={this.handleClickSet}variant="primary">Set</Button>
                </Form.Group>
            </div>
        )
    }
}

export default EditSentCount;