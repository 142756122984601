import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

class Sample extends Component{

    constructor(props){
        super(props);

    }
render(){
    return (
        <tr>
            <td className="nohov_btn">{this.props.sample.name}</td>
            <td className="nohov_btn">{this.props.sample.total}</td>
            <td className="nohov_btn">{this.props.sample.completed}</td>
            <td className="nohov_btn">{this.props.sample.rejected}</td>
            <td className="nohov_btn">{this.props.sample.exclusions}</td>
            <td className="nohov_btn">{this.props.sample.total - this.props.sample.completed - this.props.sample.rejected
            - this.props.sample.exclusions}</td>
            <td className="nohov_btn">{this.props.sample.lastRefreshed.toLocaleString('en-GB')}</td>
            <td className="nohov_btn"><Button
                variant="primary"
                onClick={() => this.props.onRefresh(this.props.sample.id)}
            >Refresh</Button></td>
        </tr>
    )
}
}

export default Sample;
