import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import Confirm from "./confirm";

class ReturnParameter extends Component {

    render(){
        return (
            <p>
                <Confirm
                    actionTitle="Delete Parameter"
                    actionDescription={"delete the " + this.props.parameter.name + " parameter? This might affect live surveys"}
                >{confirm => (
                    <Button className="expandable_btns" id="param-delete-btn"
                            variant="danger"
                            onClick={confirm(this.props.onDelete)} value={this.props.parameter.id}
                    >Delete</Button>
                )}
                </Confirm>
                {this.props.parameter.name}
            </p>

        )
    }
}

export default ReturnParameter;
