import React, {Component} from 'react';
import {Button, Badge, Form} from 'react-bootstrap';
import Confirm from "./confirm";

class Quota extends Component {

    constructor(props){
        super(props);
        this.openEditing = this.openEditing.bind(this);
        this.makeEdit = this.makeEdit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    renderStatusIndicator(active) {
        let status = "INACTIVE";
        let badgeVariant = "danger";
        let buttonText = "Activate";

        if (active) {
            status = "ACTIVE";
            badgeVariant = "success";
            buttonText = "Deactivate";
        }

        return (
            <React.Fragment>
                <Badge pill variant={badgeVariant}>{status}</Badge>
                <Button
                    variant="primary"
                    onClick={() => this.props.onChangeStatus(this.props.quota)}
                >{buttonText}</Button>
            </React.Fragment>
        );
    }

    openEditing() {

        this.setState({
            editing: true,
            name: this.props.quota.name,
            definition: this.props.quota.definition,
            target: this.props.quota.target,
            active: this.props.quota.active,
            id: this.props.quota.id
        });

    }

    makeEdit(){

        this.props.onEdit((({name, definition, target, active, id}) => ({name, definition, target, active, id}))(this.state));

        this.setState({editing: false});
    }

    handleCancel(){
        this.setState({editing: false});
    }

    state = {
        editing: false
    };



    render() {
        return (
            <tr>
                {!this.state.editing && (
                    <React.Fragment>
                        <td className="nohov_btn white">{this.props.quota.name}</td>
                        <td className="nohov_btn white">{this.props.quota.definition}</td>
                        <td className="nohov_btn white">{this.props.quota.target}</td>
                        <td className="nohov_btn white">{this.props.quota.achieved}</td>
                        <td className="nohov_btn white">{this.props.quota.target - this.props.quota.achieved}</td>
                        <td className="nohov_btn white">{this.props.quota.available}</td>
                        <td className="nohov_btn white">{this.renderStatusIndicator(this.props.quota.active)}</td>
                        <td className="nohov_btn white"><Button className="expandable_btns"
                                                                variant="secondary"
                                                                onClick={this.openEditing}
                        >Edit</Button></td>
                        <td className="nohov_btn white">
                            <Confirm
                                actionTitle="Delete Quota"
                                actionDescription={"delete the " + this.props.quota.name + " quota"}
                            >{confirm => (
                                <Button className="expandable_btns"
                                        variant="danger"
                                        onClick={confirm(this.props.onDelete)} value={this.props.quota.id}
                                >Delete</Button>
                            )}
                            </Confirm>
                        </td>
                    </React.Fragment>
                )}
                {this.state.editing && (
                    <React.Fragment>
                        <td className="nohov_btn white">
                            <Form.Control
                                type="text"
                                name="name"
                                onChange={this.handleInputChange}
                                value={this.state.name}
                            />
                        </td>
                        <td className="nohov_btn white">
                            <Form.Control
                                type="text"
                                name="definition"
                                onChange={this.handleInputChange}
                                value={this.state.definition}
                            />
                        </td>
                        <td className="nohov_btn white">
                            <Form.Control
                                type="text"
                                name="target"
                                onChange={this.handleInputChange}
                                value={this.state.target}
                            />
                        </td>
                        <td className="nohov_btn white">{this.props.quota.achieved}</td>
                        <td className="nohov_btn white">{this.props.quota.target - this.props.quota.achieved}</td>
                        <td className="nohov_btn white">{this.props.quota.available}</td>
                        <td className="nohov_btn white"></td>
                        <td className="nohov_btn white">
                            <Button className="expandable_btns"
                                    variant="primary"
                                    onClick={this.makeEdit}
                            >Save</Button></td>
                        <td className="nohov_btn white">
                            <Button className="expandable_btns"
                                    variant="secondary"
                                    onClick={this.handleCancel}
                            >Cancel</Button>
                        </td>
                    </React.Fragment>
                )}
            </tr>
        )
    }
}

export default Quota;
