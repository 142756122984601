import React, {Component} from 'react';
import {Dropdown, DropdownItem} from "react-bootstrap";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCog} from "@fortawesome/free-solid-svg-icons";
import {LinkContainer} from 'react-router-bootstrap';
import {Auth} from "aws-amplify";

class Navigation extends Component {

    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    async handleLogout() {

        await Auth.signOut();

        window.location.replace("/login");
    }

    state = {};

    render(){
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <FontAwesomeIcon icon={faCog} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <LinkContainer exact to="/">
                        <Dropdown.Item>
                            Project List
                        </Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/administration">
                        <Dropdown.Item>
                            Administration Settings
                        </Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/my-details">
                        <Dropdown.Item>
                            My Details
                        </Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/mail-template-management">
                        <Dropdown.Item>
                            Mail Templates
                        </Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/archived-projects">
                        <Dropdown.Item>
                            Archived Projects
                        </Dropdown.Item>
                    </LinkContainer>
                    <Dropdown.Item
                            onClick={this.handleLogout}
                    >Log out</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export default Navigation