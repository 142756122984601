import React, {Component} from 'react';
import {Table, Form, Button} from 'react-bootstrap';
import {API} from 'aws-amplify';

class Testers extends Component{

    constructor(props){
        super(props);

        this.state = {
            testers: "fetching",
            testerStatuses: "fetching"
        };

        this.state.selectedUserEmail = this.props.project.users[0].email

        this.updateTesterEmails = this.updateTesterEmails.bind(this);
        this.handleTesterSelect = this.handleTesterSelect.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleInvite = this.handleInvite.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    async componentDidMount() {

        this.updateTesterEmails();
    }

    async updateTesterEmails() {

        const testerStatuses = await API.get('soaptwo', '/project-tester-statuses/'
            + this.props.project.projectId);

        await this.setState({testerStatuses});

        let testers = [];

        let emailComponents = this.state.selectedUserEmail.split('@');

        for(let i = 1; i < 51; i++) {

            let email = emailComponents[0] + i + "@" + emailComponents[1];

            let matchingTesterStatus = this.state.testerStatuses.filter(tester => tester.email == email);

            let status = "fresh";

            if(matchingTesterStatus.length) {
                status = matchingTesterStatus[0].status;
            }

            testers.push({
                email: email,
                status: status,
                selected: false
            })
        }

        this.setState({testers});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleUserChange(event){

        await this.handleInputChange(event);

        this.updateTesterEmails();

    }

    handleTesterSelect(email){
        const testers = this.state.testers.map(tester => {
            if(tester.email == email) {
                tester.selected = !tester.selected;
            }
            return tester;
        })

        this.setState({testers});
    }

    async handleInvite(){

        const selectedTesters = this.state.testers.filter(tester => tester.selected)

        const testerEmails = selectedTesters.map(tester=> tester.email);

        await API.post('soap', '/mail-testers/' + this.props.project.projectNumber, {
            body: {
                emails: testerEmails,
                templateId: this.props.project.emailTemplate
            }
        });

        this.updateTesterEmails();

    }

    async handleReset(){

        const selectedTesters = this.state.testers.filter(tester => tester.selected)

        const testerEmails = selectedTesters.map(tester=> tester.email);

        await API.patch('soaptwo', '/project-tester-reset/' + this.props.project.projectId, {
            body: {
                emails: testerEmails
            }
        });

        this.updateTesterEmails();
    }

    render(){
        return (
            <React.Fragment>

                <Form.Group>
                    <Form.Label>Select Test User</Form.Label>
                    <Form.Control
                        as="select"
                        name="selectedUserEmail"
                        value={this.selectedUserEmail}
                        onChange={this.handleUserChange}
                    >
                        {this.props.project.users.map(user =>
                            <option
                                value={user.email}
                                selected={user.email == this.state.selectedUserEmail}
                            >{user.email}</option>
                        )}

                    </Form.Control>
                </Form.Group>

            <Table id="tester_list">
                <thead>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.testers != "fetching" && this.state.testers.map(tester =>
                        <tr>
                            <td className="nohov_btn white">{tester.email}</td>
                            <td className="nohov_btn white">{tester.status}</td>
                            <td className="nohov_btn white">
                                <Form.Check
                                    type="checkbox"
                                    checked={tester.selected}
                                    onChange={() =>this.handleTesterSelect(tester.email)}
                                />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </thead>
            </Table>

                <Button variant="primary"
                        onClick={this.handleInvite}
                >Invite</Button>

                <Button variant="secondary"
                        onClick={this.handleReset}
                >Reset</Button>

            </React.Fragment>
        )
    }
}

export default Testers;
