import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import {API} from 'aws-amplify';

class ParameterSelector extends Component {

    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.props.onSave(this.props.paramType, this.props.paramFor, value, this.props.defaultParam);
    }

    state = {
        params: "fetching",
        selectedParam: this.props.value
    };

    async componentDidMount() {

        const result = await API.get('soap', "/" + this.props.paramType + "params/")

        this.setState({params: result});

    }

    render() {
        return (

            <React.Fragment>
            {this.state.params != "fetching" &&(
            <Form.Group>
                <Form.Label>{this.props.title}</Form.Label>
                <Form.Control as="select"
                              name="selectedParam"
                              onChange={this.handleInputChange}
                              value={this.state.selectedParam}>
                    {this.props.defaultParam && (
                        <option value="0">{ this.props.defaultParam }</option>
                    )}
                    {this.state.params.map(param =>
                        <option value={param.id}>{ param.name }</option>
                    )}
                </Form.Control>
            </Form.Group>
                )}

            </React.Fragment>
                )

    }
}

export default ParameterSelector;