import React, {Component} from 'react';
import YouthsightUrlForSupplier from "./youthSightUrlForSupplier";

class YouthsightUrlsForSupplier extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.project.sources.filter(source => { return source.name != "Internal"})
                    .map(source =>
                    <YouthsightUrlForSupplier
                    project={this.props.project}
                    source={source}
                    onSave={this.props.onSave}
                    />
                )}
            </React.Fragment>
        )
    }
}


export default YouthsightUrlsForSupplier;