import React, {Component} from 'react';
import {Form, Button, FormControl} from 'react-bootstrap';

class EditableText extends Component{

    state = {
        editing: false,
        newValue: this.props.currentValue
    }


    constructor(props) {
        super(props);

        this.openEditing = this.openEditing.bind(this);
        this.handleClickSet = this.handleClickSet.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.inputFieldRef = React.createRef();
    }

    componentDidMount() {
        this.setState({});
    }

    async openEditing(){
        await this.setState({editing: true});

        this.inputFieldRef.focus();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleClickSet() {

        let setSuccessfully = await this.props.onSetValue(this.state.newValue);

        if(setSuccessfully) {
            this.setState({editing: false});
        }

    }


    render() {

        if(this.state.editing) {
            return (
                <Form.Group>
                    <Form.Control
                        onChange={this.handleInputChange}
                        type="text"
                        name="newValue"
                        value={this.state.newValue}
                        ref={(input) => {this.inputFieldRef = input}}
                        isInvalid={this.props.error}
                    />

                    {this.props.error && (
                        <FormControl.Feedback
                            type="invalid"
                        >{this.props.error.message}</FormControl.Feedback>
                    )}


                    <Button id="edit_set_btn" onClick={this.handleClickSet}variant="primary">Set</Button>
                </Form.Group>
            )
        } else {
            return (
                <React.Fragment>
                    <strong><p id="target_text">{this.props.currentValue}</p></strong>
                    <Button id="edit_target_button" onClick={this.openEditing} variant="primary">Edit</Button>
                </React.Fragment>
            )
        }
    }
}

export default EditableText;
