import React, { Component } from 'react';
import Project from './project.jsx';
import { Link } from 'react-router-dom';

class Projects extends  Component {


    render(){
        return (
            <div>

              <nav id="new_project_btn_container">
                <Link id="create_a_new_project_btn" className="btn btn-primary" to="/project-setup"><b>New Project</b></Link>
              </nav>
              <hr className="index_line"></hr>
              <br></br>
              <br></br>


            <table id="list_table"className="table main_table">
                <thead>
                    <tr className="nohov_btn white">
                        {this.props.projects[0].projectInfo.map(item =>
                            <th key={item.name}>{item.name}</th>
                        )}
                        <th className="nohov_btn" colSpan="3"></th>
                    </tr>
                </thead>
                <tbody>
                { this.props.projects.map(project =>
            <Project
            key = {project.id}
            project = {project}
            trafficLightLimits={this.props.trafficLightLimits}
            onDelete ={this.props.onDelete}
            />
                )}
                </tbody>
            </table>
            </div>
            )
    }
}

export default Projects;
