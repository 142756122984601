import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

class SearchResult extends Component {
    render() {
        return (
            <p>
               <Button className="add_button" onClick={this.props.onAdd} value={this.props.resultId} variant="primary">Add</Button>
                {this.props.resultName}
            
            </p>
        )
    }
}

export default SearchResult;
