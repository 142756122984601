import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import Confirm from "./confirm";
import {API} from 'aws-amplify';

class GdprDelete extends Component {

    state = {
        message: false
    };

    constructor(props) {
        super(props)

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this)
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleDelete(event) {

        this.setState({message: false})

        const gdprResult = await API.del('soap', '/gdpr/' + this.state.email);

        if(gdprResult.code == 1) {
            this.setState({message: "Panelist data removed"})
        } else if (gdprResult.code == 2000) {
            this.setState({message: "No panelist found with email address " + this.state.email})
        }
    }

    render(){
        return (
            <React.Fragment>
            <Form.Group>
                <Form.Label>Email to delete for</Form.Label>
                <Form.Control id="gdpr-form"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                />
            </Form.Group>

                {this.state.message && (
                    <p>{this.state.message}</p>
                )}

                <Confirm
                    actionTitle="Delete Email"
                    actionDescription={"delete all data associated with " + this.state.email}
                >{confirm => (
                    <Button className="expandable_btns"
                            variant="danger"
                            onClick={confirm(this.handleDelete)} value={this.state.email}
                    >Delete</Button>
                )}
                </Confirm>

            </React.Fragment>
        )
    }
}

export default GdprDelete;
