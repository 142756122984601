import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap'

class PageContentTextBox extends Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);

        this.inputFieldRef = React.createRef();

    }

    state = {
        editing: false,
        newContent: this.props.pages[this.props.pageId]
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleEdit(){
        await this.setState({editing: true});

        this.inputFieldRef.focus();
    }

    handleClickSave(){
        this.setState({editing: false});

        this.props.onSave(this.props.pageId, this.state.newContent);
    }

    render() {
        if(this.state.editing) {
        return (
            <React.Fragment>
                <Form.Group>
                    <Form.Control
                        id="page-content-form"
                        as="textarea"
                        rows="5"
                        name="newContent"
                        value={this.state.newContent}
                        onChange={this.handleInputChange}
                        ref={(input) => {this.inputFieldRef = input}}
                    />
                </Form.Group>

                <Button className="save-changes-button"
                    variant="primary"
                    onClick={this.handleClickSave}
                >Save changes</Button>
            </React.Fragment>
        )} else {
            return (
                <React.Fragment>
                    <p>{this.props.pages[this.props.pageId]}</p>

                    <Button
                        variant="primary"
                        onClick={this.handleEdit}
                    >Edit</Button>
                </React.Fragment>
            )
        }
    }
}

export default PageContentTextBox;
