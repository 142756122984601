import React, {Component} from 'react';
import EditableText from './editableText';

class ExternalSupplier extends Component {

    constructor(props) {
        super(props)

        this.onEdit = this.onEdit.bind(this)
    }

    onEdit(newName) {

        return this.props.onSupplierEdit(newName, this.props.supplier.id);
    }

    render() {
        return (
            <EditableText
                currentValue={this.props.supplier.name}
                onSetValue={this.onEdit}
            />
        )
    }

}

export default ExternalSupplier;