import React, {Component} from 'react';
import SupplierUrlForYouthsight from "./supplierUrlForYouthsight";

class SupplierUrlsForYouthsight extends Component {
    render(){
        return (
            <React.Fragment>
                {this.props.project.sources.filter(source => {
                    return source.name != "Internal";
                }).map(source =>
                    <SupplierUrlForYouthsight
                        source={source}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default SupplierUrlsForYouthsight;