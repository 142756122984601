import React, { Component } from 'react';
import Faker from 'faker';
import Projects from './projects.jsx';
import {Link} from 'react-router-dom';
import {API, Auth} from "aws-amplify";
import Navigation from "./navigation";

class projectList extends Component {



  constructor(props) {
      super(props);
      this.handleProjectDelete = this.handleProjectDelete.bind(this);

       let projects = [];

      const { clientId } = this.props.match.params;

       let clientName = Faker.company.companyName();

       this.state = {
           projects: "fetching",
           trafficLightLimits: "fetching"
       };
  }

    async componentDidMount() {


      try {

          let endpoint = "/projects"

          if(this.props.match.params.clientId) {
              endpoint = "/clients/" + this.props.match.params.clientId + "/projects";
          }

          const result = await API.get("soaptwo", endpoint);

          const projects = result.map(function(project){

              return (
                  {
                      id: project.id,
                      projectNumber: project.job_number,
                      projectName: project.name,
                      target: project.target_completes,
                      completed: project.completed,
                      clientName: project.client,
                      clientId: project.client_id,
                      expectedIncidenceRate: project.expected_incidence_rate,
                      closedDate: project.closed_date,
                      projectInfo: [
                          {key: 'jobNumber',name: 'Job Number', value: project.job_number},
                          {key: 'projectName',name: 'Project name', value: project.name},
                          {key: 'clientName', name: 'Client', value: project.client},
                          {key: 'projectProgress',name: 'Progress', value: project.completed + ' / ' + project.target_completes},
                          {key: 'responseRate',name: 'Response Rate (%)', value: project.started / project.sent * 100},
                          {key: 'incidenceRate',name: ' Incidence Rate (%) ',value: project.completed /
                                  (project.completed + project.screenout + project.quota_full + project.quality_fail) * 100},
                          {key: 'surveyLength', name: 'LOI', value: project.survey_length},
                          {key: 'dropoutRate',name: ' Drop out Rate (%) ', value: (project.dropouts / project.started * 100)},
                          {key: 'startDate',name: ' Start Date ', value: project.start_date},
                          {key: 'endDate',name: 'End Date', value: project.end_date},
                          {key: 'closedDate',name: ' Closed Date ', value: project.closed_date},
                          {key: 'defaultIncentive', name: 'Incentive', value: project.default_incentive}
                      ]

                  });
          });

          this.setState({projects: projects});
      } catch (e) {
          alert(e);
      }

      try {

          const trafficLightsresult = await API.get("soaptwo", '/trafficlightbands');

            let trafficLightLimits = {};

            console.log(trafficLightsresult);

            for(let stat of trafficLightsresult) {

                trafficLightLimits[stat.rate_type + "Rate"] = {};
                trafficLightLimits[stat.rate_type + "Rate"].red = stat.red_limit;
                trafficLightLimits[stat.rate_type + "Rate"].yellow = stat.yellow_limit;

            }

            console.log(trafficLightLimits)

            this.setState({trafficLightLimits});

      } catch(e) {
          console.log(e)
      }

    }

  async handleProjectDelete(event){

      const projectId = parseInt(event.target.value)

      const userInfo = await Auth.currentUserInfo();

      try {
          await API.del('soaptwo', '/projects/' + projectId, {
              body: {archivedBy: userInfo.username}
          });
      } catch (e) {
          alert(e)
      }

      const projects = this.state.projects.filter(p => p.id !== projectId);

      this.setState({projects});


  }

  render() {
    return (
        <div>
            <Navigation/>
          <h1 id="project_list_header">
              {this.props.match.params.clientId && (this.state.projects[0].clientName + " ")}
              Project List</h1>
          <hr className="index_line"></hr>
            {this.state.projects != "fetching" && (
          <Projects
          projects={this.state.projects}
          trafficLightLimits={this.state.trafficLightLimits}
          onDelete={this.handleProjectDelete}
          />
            )}
        </div>
    );
  }
}

export default projectList;
