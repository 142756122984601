import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import {Link, Redirect} from 'react-router-dom';

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        try {
            await Auth.signIn(this.state.email, this.state.password);
            this.props.onUserHasAuthenticated(true);
        } catch (e) {
            alert(e.message);
        }
    }


    render() {

        if (this.props.isAuthenticated === true) {
            return <Redirect to='/' />
        }

        return (
            <div className="Login">
                <form onSubmit={this.handleSubmit}>
                    <Form controlId="email" bsSize="large">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            autoFocus
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                    </Form>
                    <Form controlId="password" bsSize="large">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            name="password"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            type="password"
                        />
                    </Form>
                    <Button
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                    >
                        Login
                    </Button>
                </form>

                <Link to="/password-reset">Reset Password</Link>
            </div>
        );
    }
}
