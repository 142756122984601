import React, {Component} from 'react';
import Users from "./users";
import Filters from "./filters";
import TrafficLightSettings from "./trafficLightSettings.jsx";
import ReturnParameters from "./returnParameters";
import GdprDelete from "./gdprDelete";
import PageContentTextBox from './pageContentTextBox';
import ExternalSuppliers from './externalSuppliers';
import {Link} from 'react-router-dom';
import {API} from "aws-amplify";
import PanelVariables from "./panelVariables";
import Navigation from "./navigation";


class administration extends Component {

        constructor(props) {
                super(props);

                this.handleAddPanelVariable = this.handleAddPanelVariable.bind(this)
                this.handleFilterSave = this.handleFilterSave.bind(this);
                this.handleFilterDelete = this.handleFilterDelete.bind(this);
                this.handleFilterStatusChange = this.handleFilterStatusChange.bind(this);
                this.handlePageContentSave = this.handlePageContentSave.bind(this);
        }

        async handleAddPanelVariable(event) {
                const selectedVariableId = event.target.value;

                await API.post('soap', '/projects/0/panelvariables', {
                        body: {'variableId': selectedVariableId}
                });

                try {
                        const selectedPanelVariables = await API.get('soap',
                            '/projects/0/panelvariables');

                        this.setState({selectedPanelVariables})

                } catch (e) {
                        alert(e)
                }
        }

        async handleFilterSave(filter){

                const projectId = 0;

                let result;

                result = await API.post('soaptwo', '/sfdl-validate', {
                        body: {
                                definition: filter.newFilterDefinition,
                                projectId: projectId
                        }
                });

                if(result.code == 1) {

                        result = await API.post('soap', '/filters/', {
                                body: {
                                        name: filter.newFilterName,
                                        definition: filter.newFilterDefinition,
                                        project_id: projectId
                                }
                        });
                }

                if(result.code == 1000) {
                        this.setState({errors: {filter: result.errors}})

                        return false;
                } else {

                        try {
                                const result = await API.get('soap', "/projects/" + projectId + "/filters");

                                this.setState({filters: result});

                        } catch(e) {
                                alert(e);
                        }

                        return true
                }

        }

        async handleFilterDelete(event) {

                const id = event.target.value;

                const result = await API.del('soap', '/filters/' + id);

                const filters = this.state.filters.filter(filter => {
                        return filter.id != id;
                });

                this.setState({filters});
        }

        async handleFilterStatusChange(filter) {

                const id = filter.id;

                const result = await API.patch('soap', '/filters/' + id, {
                        body: {
                                active: !filter.active
                        }
                });

                const filters = this.state.filters.map(filter => {
                        if(filter.id == id) {
                                filter.active = result[0].active;
                                return filter;
                        } else {
                                return filter;
                        }
                });

                this.setState({filters});
        }

        handlePageContentSave(pageName, content) {

                let result = API.patch('soaptwo', '/defaultpagescontent/' + pageName, {body:
                    {content: content}
                });

                let copiedPagesContent = JSON.parse(JSON.stringify(this.state.pagesContent));

                copiedPagesContent[pageName] = content;

                console.log(copiedPagesContent);

                this.setState({pagesContent: copiedPagesContent});

        }

        state = {
                filters: "fetching",
                selectedPanelVariables: "fetching",
                pagesContent: "fetching",
                errors: {}
        };

        async componentDidMount() {
                try {
                        const result = await API.get('soap', "/projects/0/filters");

                        this.setState({filters: result});

                } catch(e) {
                        alert(e);
                }

                try {
                        const selectedPanelVariables = await API.get('soap',
                            '/projects/0/panelvariables');

                        this.setState({selectedPanelVariables})

                } catch (e) {
                        alert(e)
                }

            try {
                const result = await API.get("soaptwo", "/defaultpagescontent/");

                let pagesContent  = {};

                result.forEach(row => {
                    pagesContent[row.page_name] = row.content
                });

                this.setState({pagesContent});


            } catch (e) {
                alert(e)
            }
        }

    render() {
        return (
            <div class="container">
                    <Navigation/>
                <h1 >Administration</h1>
                <hr></hr>
                <nav id="project_btn_wrapper">
                  <Link id="project_btn_1" className="btn btn-primary" to="/">Go to project list</Link>
                  <Link id="project_btn_2" className="btn btn-primary" to={"/project-setup/"}>Go to project setup</Link>
                </nav>
                <hr></hr>

                <h2 className="admin-table-headers admin-table-big-headers">Users</h2>
                <Users></Users>


                <h2 className="admin-table-headers admin-table-big-headers">Global Panel Filters</h2>
                    {this.state.selectedPanelVariables != "fetching" && (
                    <PanelVariables
                        selectedPanelVariables={this.state.selectedPanelVariables}
                        onAddVariable={this.handleAddPanelVariable}
                    />
                    )}

                    {this.state.filters != "fetching" && this.state.panelVariables != "fetching" && (
                <Filters
                        filters={this.state.filters}
                        lastRecalculated={false}
                        availablePanelVariables={this.state.selectedPanelVariables}
                        onRecalculate={this.handleFiltersRecalculate}
                        onStatusChange={this.handleFilterStatusChange}
                        onDelete={this.handleFilterDelete}
                        onSave={this.handleFilterSave}
                        onRecalculate={this.handleFilterRecalculate}
                        errors={this.state.errors.filter ? this.state.errors.filter : {}}
                />
                    )}
                <h2 className="admin-table-headers" id="trafficLight-header">Traffic Light Bands</h2>
                <TrafficLightSettings></TrafficLightSettings>

                <h2 className="admin-table-headers" >Return parameters</h2>

                <h3>Unique Identifiers</h3>
                    <p>"yscode" is the default unique identifier return parameter. Alternative unique identifier return
                    parameters can be defined here</p>
                <ReturnParameters
                    type="uniqueIdentifier"
                />

                <h3>Result Code</h3>
                <ReturnParameters
                    type="resultCode"
                />

                <h3>GDPR Requests</h3>
                <GdprDelete/>

            {this.state.pagesContent != "fetching" && (

                <React.Fragment>

                <h3>Landing Page</h3>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="landing"
                    onSave={this.handlePageContentSave}
                />


                <h3 className="admin-table-headers">End pages</h3>

                <h5>Completed</h5>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="completed"
                    onSave={this.handlePageContentSave}
                />

                <h5>Screened out</h5>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="screenout"
                    onSave={this.handlePageContentSave}
                />

                <h5>Quota fail</h5>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="quota_full"
                    onSave={this.handlePageContentSave}
                />

                <h5>Quality fail</h5>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="quality_fail"
                    onSave={this.handlePageContentSave}
                />


                <h3 className="admin-table-headers">Error Pages</h3>

                <h5>Incorrect URL</h5>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="incorrect_url"
                    onSave={this.handlePageContentSave}
                />

                <h5>Already Completed</h5>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="already_completed"
                    onSave={this.handlePageContentSave}
                />

                <h5>Multiple Participations</h5>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="multiple_participations"
                    onSave={this.handlePageContentSave}
                />

                <h5>Project Closed</h5>
                <PageContentTextBox
                    pages={this.state.pagesContent}
                    pageId="project_closed"
                    onSave={this.handlePageContentSave}
                />


                </React.Fragment>
            )}


            <h3>External Suppliers</h3>

                    <ExternalSuppliers />


            </div>
        )
    }
}

export default administration;
