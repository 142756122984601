import React,{Component} from 'react';
import {Button} from 'react-bootstrap';
import Confirm from "./confirm";

class EmailTemplate extends Component {

    render(){
        return (
            <tr>
                <td>{this.props.template.name}</td>
                <td>{new Date(this.props.template.date_created).toLocaleDateString("en-GB")}</td>
                <td>{new Date(this.props.template.date_updated).toLocaleDateString("en-GB")}</td>
                <td>{this.props.template.last_edited_by}</td>
                <td>{this.props.template.tags}</td>
                <td>
                    <Button
                        variant="primary"
                        value={this.props.template.id}
                        onClick={this.props.onEdit}
                    >Edit</Button>
                </td>
                <td>
                    <Button
                        variant="secondary"
                        value={this.props.template.id}
                        onClick={this.props.onCopy}
                    >Copy</Button>
                </td>
                <td>
                    <Confirm
                        actionTitle = "Delete Email Template"
                        actionDescription = {"delete the " + this.props.template.name + " email template"}
                    >
                        {confirm => (
                            <button onClick={confirm(this.props.onDelete)}
                                    className="btn btn-danger" value={this.props.template.id}>Delete</button>
                        )}
                    </Confirm>
                </td>
            </tr>
        )
    }

}

export default EmailTemplate;
