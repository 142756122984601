import React, {Component} from "react";
import { Modal, Button} from 'react-bootstrap';

class Confirm extends Component{

    state = {
        open: false,
        callback: null
    }

    constructor(props){
        super(props)
    }

    show = callback => event => {

        event.preventDefault();

        event = {
            ...event,
            target: {...event.target, value: event.target.value }
        }

        this.setState({
            open: true,
            callback: () => callback(event)
        })
    }

    hide = () => this.setState({open: false, callback: null});

    confirm = () => {

        this.state.callback();
        this.hide();
    }

    render(){
        return (
            <React.Fragment>
                { this.props.children(this.show) }


                <Modal show={this.state.open}>
                    <Modal.Header>
                        <Modal.Title>{ this.props.actionTitle}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Are you sure you want to {this.props.actionDescription}?</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.hide} variant="secondary">Cancel</Button>
                        <Button onClick={this.confirm} variant="primary">OK</Button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment>
        )
    }
}

export default Confirm;
