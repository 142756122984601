import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import EditableText from "./editableText";

class TrafficLightSetting extends Component {

    render() {
        return (
            <div>
                {['yellow', 'red'].map( colour =>

                    <Form.Group>
                        <Form.Label className="trafficLight-label">{this.props.setting.label} {colour} upper limit</Form.Label>
                        {this.props.setting[colour] != null && (
                        <EditableText
                            currentValue={this.props.setting[colour]}
                            onSetValue={(limit) => {this.props.onSetLimit(limit, colour, this.props.setting.key)}}
                        />
                        )}
                    </Form.Group>
                )}
                <hr id="trafficLight-hr-line"></hr>
            </div>
        )
    }
}

export default TrafficLightSetting;
