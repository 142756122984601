import React, { Component } from 'react';
import {Table} from 'react-bootstrap';
import {API} from "aws-amplify";

class CompletionStatsTable extends Component {

    state = {
        stats: "fetching"
    }

    async componentDidMount() {

        try {
            const statsResult = await API.get('soaptwo', '/completion-stats/' +
                this.props.projectId + '/' + this.props.interval);

            this.setState({stats: statsResult});

        } catch(error) {
            console.log(error);
        }

    }

    render(){
        return (
            <React.Fragment>
                <h4>Per {this.props.interval}</h4>
                <Table className="main_table">
                    <thead>
                    <tr>
                        <th style={{textTransform: 'capitalize'}}>{this.props.interval} From</th>
                        <th>Starts</th>
                        <th>Completes</th>
                        <th>Screenouts</th>
                        <th>Quota fulls</th>
                        <th>Quality fails</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.stats != "fetching" && (
                        this.state.stats.map(row =>
                            <tr>
                                <td>{new Date(row.intervalStartDate).toLocaleDateString("en-GB")}</td>
                                <td>{row.starts}</td>
                                <td>{row.complets}</td>
                                <td>{row.screenouts}</td>
                                <td>{row.quota_fulls}</td>
                                <td>{row.quality_fails}</td>
                            </tr>
                        )
                    )}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}
export default CompletionStatsTable;
