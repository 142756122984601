import React, {Component} from 'react';
import {Table, Form, Button, FormControl} from 'react-bootstrap';
import Incentive from "./incentive";
import {API} from "aws-amplify";
import EditableText from "./editableText";


class Incentives extends Component {

    constructor(props){
        super(props);

        this.handleToggleNewIncentive = this.handleToggleNewIncentive.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }

    getIncentives(){

        let filtersWithPoints = this.props.project.filters.filter(filter => {
            return filter.points > 0;
        });

        filtersWithPoints = filtersWithPoints.map(filter => {
            return {...filter, type: "filter"}
        });

        let quotasWithPoints = this.props.project.quotas.filter(quota => {
            return quota.points > 0;
        });

        quotasWithPoints = quotasWithPoints.map(quota => {
            return {...quota, type: "quota"}
        });

        return [...filtersWithPoints, ...quotasWithPoints];
    }

    async componentDidMount(){
        try {
            const incentivesAwarded = await API.get("soaptwo", "/projects/"  + this.props.project.projectId + "/pointsawarded");

            this.setState({incentivesAwarded});

        } catch (e) {
            alert(e)
        }
    }

    handleToggleNewIncentive() {
        this.setState({newIncentiveOpen: !this.state.newIncentiveOpen});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleClickSave(){
        const setSucessfully = await this.props.onSave(this.state);

        if(setSucessfully) {
            this.handleToggleNewIncentive();
        }
    }

    handleEdit(editing){

        this.setState({
            newIncentiveQuota: editing.quota,
            newIncentiveFilter: editing.filter,
            newIncentivePoints: editing.points,
            newIncentiveOpen: true
        })
    }

    state = {
        newIncentiveOpen: false,
        newIncentiveType: 'quota',
        newIncentiveFilterOrQuota: null,
        newIncentivePoints: null,
        incentivesAwarded: "fetching"
    }

    render(){
        return (
            <React.Fragment>
            <br></br>
            <Table>
                <thead>
                    <tr>
                        <th>Incentive level (points)</th>
                        <th>Number of panellists awarded</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.incentivesAwarded != "fetching" && this.state.incentivesAwarded.map(incentive =>
                    <tr>
                        <td className="nohov_btn">{incentive.incentiveLevel}</td>
                        <td className="nohov_btn">{incentive.count}</td>
                    </tr>
                )}
                </tbody>

            </Table>
            <br></br>

                <Form.Group>
                    <Form.Label>Default Incentive Level</Form.Label>
                    {this.props.project.defaultIncentive != "fetching" && (
                        <EditableText
                            currentValue={this.props.project.defaultIncentive}
                            onSetValue={this.props.onSetDefaultIncentive}
                            error={this.props.errors.default_incentive}
                        />
                    )}
                </Form.Group>

                <br></br>
                <br></br>

                <Table className="white">
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Points</th>
                        <th className="nohov_btn" colSpan="2"></th>
                    </tr>
                    </thead>
                    <tbody>
                {this.getIncentives().map(incentive =>
                <Incentive
                    incentive={incentive}
                    onDelete={this.props.onDelete}
                    onEdit={this.handleEdit}
                />
                )}
                    </tbody>
                </Table>

                {this.state.newIncentiveOpen && (
                    <React.Fragment>

                        <Form.Check
                            name="newIncentiveType"
                            type="radio"
                            label="Quota"
                            value="quota"
                            checked={this.state.newIncentiveType == 'quota'}
                            onChange={this.handleInputChange}
                        />
                        <Form.Check
                            name="newIncentiveType"
                            type="radio"
                            label="Filter"
                            value="filter"
                            checked={this.state.newIncentiveType == 'filter'}
                            onChange={this.handleInputChange}
                        />

                        {this.state.newIncentiveType == 'quota' && (
                        <Form.Group>
                            <Form.Label>Quota</Form.Label>
                            <Form.Control as="select"
                                          name="newIncentiveFilterOrQuota"
                                          onChange={this.handleInputChange}
                                          value={this.state.newIncentiveFilterOrQuota}>
                                <option value="">Select</option>
                                {this.props.project.quotas.map(quota =>
                                <option value={quota.id}>{quota.name}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        )}

                        {this.state.newIncentiveType == 'filter' && (
                        <Form.Group>
                            <Form.Label>Filter</Form.Label>
                            <Form.Control as="select"
                                          name="newIncentiveFilterOrQuota"
                                          onChange={this.handleInputChange}
                                          value={this.state.newIncentiveFilterOrQuota}>
                                <option value="">Select</option>
                            {this.props.project.filters.map(filter =>
                                <option value={filter.id}>{filter.name}</option>
                            )}
                            </Form.Control>
                        </Form.Group>
                        )}


                        <Form.Group>
                            <Form.Label>Points</Form.Label>
                            <Form.Control type="text"
                                          name="newIncentivePoints"
                                          onChange={this.handleInputChange}
                                          value={this.state.newIncentivePoints}
                                          isInvalid={this.props.errors.points}
                            />

                            {this.props.errors.points && (
                                <FormControl.Feedback
                                    type="invalid"
                                >{this.props.errors.points.message}</FormControl.Feedback>
                            )}
                        </Form.Group>


                        <Button
                            variant="primary"
                            onClick={this.handleClickSave}
                        >Save</Button>
                        <Button
                            variant="secondary"
                            onClick={this.handleToggleNewIncentive}
                        >Cancel</Button>

                    </React.Fragment>
                )}

                <Button onClick={this.handleToggleNewIncentive}>New</Button>
            </React.Fragment>
        )
    }
}

export default Incentives;
