import React, {Component} from 'react';
import {Button, Form} from "react-bootstrap";
import {Auth, Amplify, API} from 'aws-amplify'
import {Link} from "react-router-dom";
import Navigation from "./navigation";

class myDetails extends Component {

    state = {
        password: "",
        email: "",
        username: "",
        message: ""
    }

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    async componentDidMount(){
        const userInfo = await Auth.currentUserInfo();
        console.log(userInfo);

        this.setState({
           email: userInfo.attributes.email,
           username: userInfo.username
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        console.log(name);

        this.setState({
            [name]: value
        });
    }

    async handleSave(){

            await API.patch('soapthree', '/users', {
                'body': {
                    email: this.state.email,
                    username: this.state.username,
                    password: this.state.password
                }
            });

            this.setState({message: "Updated"});
    }

    render() {
        return (
            <React.Fragment>

                <Navigation/>

                <p>Update User Details</p>

                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="text"
                        name="email"
                        onChange={this.handleInputChange}
                        value={this.state.email}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        name="password"
                        onChange={this.handleInputChange}
                        value={this.state.password}
                    />
                </Form.Group>

                <Button
                    variant="primary"
                    onClick={this.handleSave}
                >Save</Button>

                <p>{this.state.message}</p>
            </React.Fragment>
        )
    }

}

export default myDetails;