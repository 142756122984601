import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import {API} from 'aws-amplify';

class SelectProjectClient extends Component {

    state = {
        searchOpen: false,
        clients: "fetching",
        displayedClients: []
    };

    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveNew = this.handleSaveNew.bind(this);
        this.handleSelectClick = this.handleSelectClick.bind(this)
    }

    async componentDidMount() {

        const result = await API.get('soaptwo', '/clients')

        this.setState({clients: result});

        const clientName = this.state.clients.filter(client =>
            client.id == this.props.clientId
        )[0].name;

        this.setState({clientName});

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChange(event){

        this.setState({searchOpen: true});

        this.handleInputChange(event);

        let searchTerm = event.target.value.toLowerCase();

        const displayedClients = this.state.clients.filter((client) => {
            let searchValue = client.name.toLowerCase();
            return searchValue.indexOf(searchTerm) !== -1;
        });

        this.setState({displayedClients});
    }

    async handleSaveNew() {

        const result = await API.post('soaptwo', '/clients', {
            body: {
                name: this.state.clientName,
            }
        }) ;
    }

    handleSelectClick(event){
        this.props.onSelect(event);

        this.setState({searchOpen: false, displayedClients: []})

        const clientName = this.state.clients.filter(client =>
            client.id == event.target.value
        )[0].name;

        this.setState({clientName})
    }


    render() {
        return (
            <React.Fragment>

            <Form.Group>
                <Form.Label>Client/Company</Form.Label>
                {this.state.clients != "fetching" && (
                <Form.Control
                    name="clientName"
                    type="text"
                    value={this.state.clientName}
                    onChange={this.handleChange}
                />
                )}
            </Form.Group>

                {this.state.searchOpen &&(

                <Button

                    /* //JFTODO wrap this in a confirm? */
                    variant="primary"
                    onClick={this.handleSaveNew}
                >Save as new client</Button>

                )}

                {this.state.displayedClients.map(client =>
                    <p>
                        <Button
                            variant="primary"
                            onClick={this.handleSelectClick}
                            value={client.id}
                        >Select</Button>
                        {client.name}
                    </p>
                )}
            </React.Fragment>
        )
    }
}

export default SelectProjectClient;