export default {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://qjp7ne6t1d.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_xKixjAVUX",
        APP_CLIENT_ID: "54qsdg1ch89nrb0cb8tre4q2i5",
        IDENTITY_POOL_ID: "us-east-1:9e96e2ca-1eb4-484f-9ae9-f6c2b592da87"
    }
};
