import React, {Component} from 'react';
import {Form, Table} from 'react-bootstrap';
import Sample from "./sample";
import SearchResult from "./searchResult";

class Samples extends Component{

    state = {
        samplesSearchTerm:""
    }

    constructor(props) {
        super(props);

        this.handleSearchType = this.handleSearchType.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this)
    }


    async handleSearchType(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        await this.setState({
            [name]: value
        });

        this.props.onSamplesSearch(this.state.samplesSearchTerm);
    }

    handleAddClick(event){

        this.setState({samplesSearchTerm: ""});

        const selectedSampleId = event.target.value;

        this.props.onAddSample(selectedSampleId);
    }

    render(){
        return (
            <React.Fragment>
            <Table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Total</th>
                    <th>Completed</th>
                    <th>Rejected</th>
                    <th>Filter/Quota Exclusions</th>
                    <th>Available</th>
                    <th>Last refreshed</th>
                    <th className="nohov_btn"></th>
                </tr>
                </thead>
                <tbody>
                {this.props.project.selectedSamples != "fetching" && this.props.project.selectedSamples.map(sample =>
                    <Sample
                        sample={sample}
                        onRefresh={this.props.onRefresh}
                    />
                )}
                </tbody>
            </Table>
            <hr></hr>
            <br></br>
                {this.props.project.samples != "fetching" && (
                    <Form.Group>
                        <Form.Label>Search Samples to Add</Form.Label>
                        <Form.Control
                            type="text"
                            name="samplesSearchTerm"
                            value={this.state.samplesSearchTerm}
                            onChange={this.handleSearchType}/>
                    </Form.Group>
                )}
                {this.props.project.displayedSamples.map(sample =>
                    <SearchResult
                        resultId={sample.sampleId}
                        resultName={`${sample.psuName}  |  ${sample.name}  |  ${sample.sampleSize} members`}
                        onAdd={this.handleAddClick}
                    />
                )}


            </React.Fragment>
        )
    }
}

export default Samples;
