import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import Confirm from "./confirm";

class User extends Component {
    constructor(props){
        super(props);
        this.handleEdit = this.handleEdit.bind(this);
    }

    handleEdit() {
        this.props.onEdit({
            name: this.props.user.username,
            email: this.props.user.email,
        });
    }

    render() {
        return (
            <tr>
                <td className="no-ab-hov">{this.props.user.username}</td>
                <td className="no-ab-hov">{this.props.user.email}</td>
                <td className="no-ab-hov">{this.props.user.last_login}</td>
                <td className="no-ab-hov">{this.props.user.last_ip}</td>
                <td className="nohov_btn white">
                    <Button
                        variant="secondary"
                        onClick={this.handleEdit}>
                        Edit
                    </Button>
                </td>
                <td className="nohov_btn white">
                    <Confirm
                        actionTitle="Delete User"
                        actionDescription={"delete " + this.props.user.username}
                    >{confirm => (
                        <Button
                            variant="danger"
                            onClick={confirm(this.props.onDelete)}
                            value={this.props.user.username}
                        >Delete</Button>
                    )}
                    </Confirm>
                </td>
            </tr>
        )
    }
}

export default User;
