import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

class ArchivedProject extends Component {

    render(){
        return (
            <tr>
                <td>{this.props.project.name}</td>
                <td>{this.props.project.archived_by}</td>
                <td>{new Date(this.props.project.start_date).toLocaleDateString("en-GB")}</td>
                <td>{new Date(this.props.project.closed_date).toLocaleDateString("en-GB")}</td>
                <td>{this.props.project.sent}</td>
                <td>{this.props.project.completed}</td>
                <td>{Math.round(this.props.project.started / this.props.project.sent * 100)}</td>
                <td>{Math.round(
                    (this.props.project.completed + this.props.project.screenout + this.props.project.quota_full
                        + this.props.project.quality_fail)
                    /
                    this.props.project.sent * 100)}</td>
                <td>{Math.round(this.props.project.completed / this.props.project.started * 100)}</td>
                <td>{
                    Math.round((this.props.project.started - this.props.project.completed
                        - this.props.project.screenout - this.props.project.quota_full - this.props.project.quality_fail) / this.props.project.started
                    * 100)
                }</td>
                <td>
                    <Button
                        variant="primary"
                        value={this.props.project.id}
                        onClick={this.props.onRestore}
                    >Restore</Button>
                </td>
            </tr>
        )
    }

}

export default ArchivedProject;