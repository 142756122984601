import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {Form, Button} from 'react-bootstrap';

class PasswordReset extends Component {

    state = {
        username: "",
        codeSent: false,
        code: '',
        password: '',
        confirmPassword:'',
        errors: {},
        resetComplete: false
    };

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRequestCode = this.handleRequestCode.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleRequestCode(){
        let requestCodeResult = await Auth.forgotPassword(this.state.username);

        this.setState({codeSent: true})
    }

    async handlePasswordReset(){

        await this.setState({errors: {}});

        let hasErrors = false;

        if(this.state.password.length < 12) {
            this.setState({errors: {...this.state.errors, password: 'Password must be at least 12 characters'}})

            hasErrors = true;
        }

        if(this.state.password != this.state.confirmPassword) {
            this.setState({errors: {...this.state.errors, confirmPassword: 'Password don\'t match'}})

            hasErrors = true;
        }

        if(!hasErrors) {
            try {
                let passwordResetResult = await Auth.forgotPasswordSubmit(this.state.username, this.state.code, this.state.password);

                this.setState({resetComplete: true})
            } catch(error) {

            }


        }

    }

    render() {
        return (
            <React.Fragment>
            {!this.state.resetComplete && (
            <React.Fragment>
                {!this.state.codeSent && (
            <React.Fragment>
                <Form.Group bsSize="large" controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        autoFocus
                        type="text"
                        name="username"
                        value={this.state.username}
                        onChange={this.handleInputChange}
                    />
                </Form.Group>
                <Button
                    variant="primary"
                    onClick={this.handleRequestCode}
                >Request reset code</Button>
            </React.Fragment>
                    )}

                {this.state.codeSent && (
                    <React.Fragment>
                        <Form.Group bsSize="large" controlId="code">
                            <Form.Label>Code</Form.Label>
                            <Form.Control
                                autoFocus
                                type="text"
                                name="code"
                                value={this.state.code}
                                onChange={this.handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group bsSize="large" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                autoFocus
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleInputChange}
                                isInvalid={this.state.errors.password}
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.password}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group bsSize="large" controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                autoFocus
                                type="password"
                                name="confirmPassword"
                                value={this.state.confirmPassword}
                                onChange={this.handleInputChange}
                                isInvalid={this.state.errors.confirmPassword}
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.confirmPassword}</Form.Control.Feedback>
                        </Form.Group>

                        <Button
                            variant="primary"
                            onClick={this.handlePasswordReset}
                        >Reset Password</Button>
                    </React.Fragment>
                )}
            </React.Fragment>
            )}
            {this.state.resetComplete && (
                <p>Password reset complete. <Link to="/login">Log in here</Link></p>
            )}
            </React.Fragment>
        )
    }

}

export default PasswordReset;