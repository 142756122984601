import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import Faker from 'faker';
import ParameterSelector from './parameterSelector';

class YouthsightUrlForSupplier extends Component {

    render(){
        return (
            <React.Fragment>

                <h3>{this.props.source.name}</h3>

                <ParameterSelector
                    title="Unique Identifier Param"
                    paramType="uniqueidentifier"
                    defaultParam="yscode"
                    paramFor={this.props.source.supplier_id}
                    value={this.props.source.unique_identifier_param_id}
                    onSave={this.props.onSave}
                />

                <p id="supplier_ptag">{window.location.origin.toString()}/landing?jobno={this.props.project.projectNumber}
                &supplier={this.props.source.code}
                &{this.props.source.unique_identifier_param_name ? this.props.source.unique_identifier_param_name : 'id'}=</p>
                <hr></hr>


              
            </React.Fragment>
        )
    }
}

export default YouthsightUrlForSupplier;
