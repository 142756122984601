import React, {Component} from 'react';
import ExternalSupplier from './externalSupplier.jsx'
import {Button, Form} from 'react-bootstrap';
import {API} from 'aws-amplify';

class ExternalSuppliers extends Component {

    state = {
        suppliers: "fetching",
        addOpen: false
    }

    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSupplierEdit = this.handleSupplierEdit.bind(this);
        this.toggleAdd = this.toggleAdd.bind(this);
        this.handleAddSupplier = this.handleAddSupplier.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    toggleAdd(){

        this.setState({addOpen: !this.state.addOpen})

    }

    async handleSupplierEdit(name, id) {
        const updateResult = await API.patch('soaptwo', '/suppliers/' + id,
            {body: {
                    name: name,
                }});

        if(updateResult[0].name == name) {

            const suppliers = this.state.suppliers.filter(function(supplier){

                if(supplier.id == id) {

                    supplier.name = name;
                }

                return supplier

            });

            this.setState({suppliers});

            return true;
        }
    }

    async handleAddSupplier(){

        const addSupplierResult = await API.post('soaptwo', '/suppliers',
            {body: {
                    name: this.state.newSupplierName
                }});

        if(addSupplierResult.code == 1) {
            this.setState({suppliers: [...this.state.suppliers, addSupplierResult.data]});
        }

        this.setState({newSupplierName: ""})

        this.toggleAdd();
    }

    async componentDidMount() {

        const suppliersResult = await API.get('soaptwo', '/suppliers')

        this.setState({suppliers: suppliersResult});
    }

    render() {
        return (

            <React.Fragment>

            {this.state.suppliers != "fetching" && this.state.suppliers.map(supplier =>

                <ExternalSupplier
                    supplier={supplier}
                    onSupplierEdit={this.handleSupplierEdit}
                />

            )}

            <br/>

                {this.state.addOpen && (

                    <React.Fragment>

                        <Form.Group controlId="supplierName">
                            <Form.Label>Supplier Name</Form.Label>
                                <Form.Control
                                    onChange={this.handleInputChange}
                                    type="text"
                                    name="newSupplierName"
                                    value={this.state.newSupplierName}
                                />
                        </Form.Group>

                        <Button
                            variant={"primary"}
                            onClick={this.handleAddSupplier}
                        >Add</Button>

                        <Button
                            variant={"secondary"}
                            onClick={this.toggleAdd}
                        >Cancel</Button>
                    </React.Fragment>
                )}


                {!this.state.addOpen && (
                <Button
                    variant={"primary"}
                    onClick={this.toggleAdd}
                >Add New</Button>
                )}
            </React.Fragment>
        )
    }
}

export default ExternalSuppliers;