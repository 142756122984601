import React, {Component} from 'react';
import EmailTemplate from './emailTemplate';
import EmailTemplateEditor from './emailTemplateEditor';
import {Form} from 'react-bootstrap';
import {API, Auth} from 'aws-amplify';
import {Link} from "react-router-dom";
import Navigation from "./navigation";

class EmailTemplates extends Component {

    state = {
        templates: "fetching",
        dynamicFields: "fetching",
        displayedTemplates: [],
        editing: false,
        templateUnderEdit: {},
        searchString: ''
    };

    constructor(props){
        super(props);

        this.handleEdit = this.handleEdit.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.handleTemplateDelete = this.handleTemplateDelete.bind(this);

        this.setContentPreview = this.setContentPreview.bind(this);

        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleTemplatePropertyChange = this.handleTemplatePropertyChange.bind(this);
        this.handleTemplateSave = this.handleTemplateSave.bind(this);

        this.handleSearch = this.handleSearch.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    async handleEdit(event) {
        const templateId = event.target.value;

        const templateUnderEdit = JSON.parse(JSON.stringify(
            this.state.templates.filter(template => template.id == templateId)[0]));

        await this.setState({templateUnderEdit});

        this.setContentPreview();

        this.toggleEdit();
    }

    setContentPreview() {

        let templateUnderEdit = this.state.templateUnderEdit;

        let contentWithExampleDynamicText = templateUnderEdit['content'];

        for (const field of this.state.dynamicFields) {

            console.log(field.varname, field.demo);

            contentWithExampleDynamicText =
                contentWithExampleDynamicText.replace(field.varname, field.demo);
        }

        contentWithExampleDynamicText = contentWithExampleDynamicText.replace('[[link]]',
            'https://field.opinionpanel.co.uk/landing');

        templateUnderEdit['contentPreview'] = contentWithExampleDynamicText;

        this.setState({templateUnderEdit})

    }

    handleCopy(event) {
        const templateId = event.target.value;

        let templateUnderEdit = JSON.parse(JSON.stringify(
            this.state.templates.filter(template => template.id == templateId)[0]));

        templateUnderEdit.id = null;

        this.setState({templateUnderEdit});

        this.toggleEdit();
    }

    toggleEdit(){
        this.setState({editing: !this.state.editing});
    }

    async handleTemplateDelete(event) {
        const templateId = event.target.value;

        const deleteResult = await API.del('soaptwo', '/email-templates/' + templateId);

        const templates = this.state.templates.filter(template => template.id != templateId);

        this.setState({templates});

        this.handleSearch();
    }

    async handleTemplatePropertyChange(event) {

        const propertyName = event.target.name;

        const newValue = event.target.value;

        let templateUnderEdit = this.state.templateUnderEdit;

        templateUnderEdit[propertyName] = newValue;

        await this.setState({templateUnderEdit})

        this.setContentPreview();
    }

    async handleTemplateSave(){

        const userInfo = await Auth.currentUserInfo();

        let httpVerb = "patch";

        if(!this.state.templateUnderEdit.id) {
            httpVerb = "post"
        }

        let idString = ''

        if(this.state.templateUnderEdit.id) {
            idString = this.state.templateUnderEdit.id;
        }

        const updateResult = await API[httpVerb]('soaptwo', '/email-templates/'
            + idString, {
            body: {
                name: this.state.templateUnderEdit.name,
                tags: this.state.templateUnderEdit.tags,
                content: this.state.templateUnderEdit.content,
                date_updated: new Date().toISOString().split('T')[0],
                last_edited_by: userInfo.username
            }
        });

        const templates =
            [...this.state.templates.filter(template => template.id != updateResult[0].id), updateResult[0]]

        this.setState({templates});

        this.handleSearch();

        this.toggleEdit();

    }

    async onSearch(event) {

        const searchString = event.target.value;

        await this.setState({searchString});

        this.handleSearch();
    }

    async handleSearch(){

        const displayedTemplates = this.state.templates.filter(function(template){

            console.log(template['content']);

            return Object.keys(template).reduce((accumulator, key) => {

                return accumulator || String(template[key]).indexOf(this.state.searchString) > -1;

            }, false);



        }, this);

        this.setState({displayedTemplates});
    }

    async componentDidMount() {

        const templates = await API.get('soaptwo', '/email-templates');

        this.setState({templates});

        this.handleSearch();

        const dynamicFields = await API.get('soaptwo', '/email-dynamic-fields');

        this.setState({dynamicFields});

    }

    render(){
        return (

            <React.Fragment>
                <div className="container">

                    <Navigation/>

                <h1>Mail Template Management</h1>

                <Form.Group>
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.searchString}
                        onChange={this.onSearch}
                    />
                </Form.Group>

                <table id="list_table"className="table main_table">
                    <thead>
                    <tr className="nohov_btn white">
                        <th>Name</th>
                        <th>Date created</th>
                        <th>Date last edited</th>
                        <th>Last edited by</th>
                        <th>Tags</th>
                        <th colSpan="3"></th>
                    </tr>
                    </thead>

                {this.state.templates != "fetching" && this.state.displayedTemplates.map(template =>

                    <EmailTemplate
                        template={template}
                        onEdit={this.handleEdit}
                        onCopy={this.handleCopy}
                        onDelete={this.handleTemplateDelete}
                    />

                )}

                </table>

                    <EmailTemplateEditor
                        open={this.state.editing}
                        template={this.state.templateUnderEdit}
                        dynamicFields={this.state.dynamicFields}
                        toggleOpen={this.toggleEdit}
                        onPropertyChange={this.handleTemplatePropertyChange}
                        onSave={this.handleTemplateSave}
                    />



                </div>
            </React.Fragment>


        )
    }

}

export default EmailTemplates