import React, {Component} from 'react';
import Faker from 'faker';
import {API} from 'aws-amplify';

class TestUrls extends Component {

    state = {
        urls: "fetching"
    }

    constructor(props){
        super(props)

    }

    async componentDidMount() {

        const baseUrl =
            window.location.origin.toString() + "/landing?jobno=" +
            this.props.project.projectNumber +
            "&yscode=";

        let yscodes = await API.get('return', '/test-ys-codes/' + this.props.project.projectNumber);

        let urls = yscodes.map(code => baseUrl + encodeURIComponent(code));

        this.setState({urls});

    }


    render() {
        return (
            <div>
                {this.props.project.projectNumber != "fetching"
                && this.state.urls != "fetching" && this.state.urls.map(url =>
                    <p><a href={url} target="_blank">{url}</a></p>
                )}
            </div>
        )
    }
}

export default TestUrls;
