import React, {Component} from 'react';
import {Badge, Button} from "react-bootstrap";
import Confirm from "./confirm";

class Filter extends Component{
    constructor(props){
        super(props);
        this.handleEdit = this.handleEdit.bind(this);
    }

    renderStatusIndicator(active) {
        let status = "INACTIVE";
        let badgeVariant = "danger";
        let buttonText = "Activate";

        if (active) {
            status = "ACTIVE";
            badgeVariant = "success";
            buttonText = "Deactivate";
        }

        return (
            <React.Fragment>
                <Badge pill variant={badgeVariant}>{status}</Badge>
                <Button
                    variant="primary"
                    onClick={() => this.props.onChangeStatus(this.props.filter)}
                >{buttonText}</Button>
            </React.Fragment>
        );
    }

    handleEdit() {
        this.props.onEdit({
            name: this.props.filter.name,
            definition: this.props.filter.definition,
            target: this.props.filter.target
        });


        //JFTODO make this less hacky

        this.props.onDelete({target: {value: this.props.filter.id}})
    }



    render() {
        return (
            <tr>
                <td className="nohov_btn white">{this.props.filter.name}</td>
                <td className="nohov_btn white">{this.props.filter.definition}</td>
                <td className="nohov_btn white">{this.props.filter.inFilter}</td>
                <td className="nohov_btn white">{this.props.filter.filteredOut}</td>
                <td className="nohov_btn white">{this.renderStatusIndicator(this.props.filter.active)}</td>
                <td className="nohov_btn white"><Button className="expandable_btns"
                    variant="secondary"
                    onClick={this.handleEdit}
                >Edit</Button></td>
                <td className="nohov_btn white">
                    <Confirm
                        actionTitle="Delete Filter"
                        actionDescription={"delete the " + this.props.filter.name + " filter"}
                    >{confirm => (
                        <Button className="expandable_btns"
                            variant="danger"
                            onClick={confirm(this.props.onDelete)} value={this.props.filter.id}
                        >Delete</Button>
                    )}
                    </Confirm>
                </td>
            </tr>
        )
    }
}

export default Filter;
