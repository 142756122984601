import React, {Component} from 'react';
import {Form, Table, Button, Modal} from 'react-bootstrap';
import Confirm from "./confirm";
import {API} from 'aws-amplify';

class InvitesReminders extends Component{

    constructor(props) {
        super(props);
    }


    state = {
        isError: false,
        errorMessage: ""
    }

    async componentDidMount() {
        this.props.onSetQuotaCombinationsAvailableStat()
    }

    render() {
        return (
            <React.Fragment>

                <br></br>
                <hr></hr>
                <Form.Check
                type="radio"
                label="Invite"
                checked={this.props.invite}
                value="invite"
                onChange={this.props.onInviteToggle}
                />
                <Form.Check
                    type="radio"
                    label="Remind"
                    value="remind"
                    checked={!this.props.invite}
                    onChange={this.props.onInviteToggle}
                />

                <br></br>

                {!this.props.invite && (
                    <React.Fragment>
                    <Form.Check
                        type="radio"
                        label="Invited"
                        checked={this.props.remindStatus == "invited"}
                        value="invited"
                        onChange={this.props.onStatusReminderToggle}
                    />
                    <Form.Check
                    type="radio"
                    label="Started"
                    value="started"
                    checked={this.props.remindStatus == "started"}
                    onChange={this.props.onStatusReminderToggle}
                    />
                    <Form.Check
                        type="radio"
                        label="Both"
                        value="both"
                        checked={this.props.remindStatus == "both"}
                        onChange={this.props.onStatusReminderToggle}
                    />
                    </React.Fragment>
                )}

                <Form.Group>
                {this.props.project.quotas.map(quota =>
                    <Form.Check
                        type="checkbox"
                        label={quota.name}
                        value={quota.id}
                        checked={this.props.selectedQuotas.includes(quota.id)}
                        onChange={this.props.onSelectQuota}
                    />
                )}
                </Form.Group>
                <Button
                    variant="primary"
                    onClick={this.props.onSelectQuotas}
                >Select Quotas</Button>

                <Table>
                    <thead>
                        <tr>
                            <th className="nohov_btn"></th>
                            <th>Target</th>
                            <th>Completed</th>
                            <th>To Get</th>
                            <th>Available</th>
                            <th>To Send</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="nohov_btn white">Total</td>
                        <td className="nohov_btn white">{this.props.project.target}</td>
                        <td className="nohov_btn white">{this.props.project.totalCompletes}</td>
                        <td className="nohov_btn white">{this.props.project.target - this.props.project.totalCompletes}</td>
                        <td className="nohov_btn white">{this.props.quotasTotalAvailableStat}</td>
                        <td className="nohov_btn white">
                            <Form.Control
                                type="text"
                                name="totalToSend"
                                value={this.props.totalToSend}
                                onChange={this.props.onTotalToSendChange}
                            />
                        </td>
                    </tr>
                    {this.props.quotaCombinations.map(quota =>
                        <tr>
                            <td className="nohov_btn white">{quota.name}</td>
                            <td className="nohov_btn white">{quota.target}</td>
                            <td className="nohov_btn white">{quota.achieved}</td>
                            <td className="nohov_btn white">{quota.target - quota.achieved}</td>
                            <td className="nohov_btn white">{quota.available}</td>
                            <td className="nohov_btn white">
                                <Form.Control
                                    type="text"
                                    name={quota.quotaIds.join('#')}
                                    value={quota.toSend}
                                    onChange={this.props.onToSendChange}
                                />
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                <Form.Group>
                    <Form.Label>x Still To Get</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.props.xToGet}
                        name="xToGet"
                        onChange={this.props.onDistribute}
                    />
                </Form.Group>

                <Button variant="secondary"
                        name="distributeToSend"
                        value="xToGet"
                        onClick={this.props.onDistribute}
                >Distribute</Button>
                <Button variant="secondary"
                        name="distributeToSend"
                        value="max"
                        onClick={this.props.onDistribute}
                >Max</Button>
                <Button variant="secondary"
                        name="distributeToSend"
                        value="clear"
                        onClick={this.props.onDistribute}
                >Clear</Button>

                <Confirm
                    actionTitle="Send invite emails"
                    actionDescription={`send ${this.props.totalToSend} invite emails`}
                >
                    { confirm => (
                        <Button variant="primary"
                                onClick={confirm(this.props.onSend)}
                        >Send</Button>
                    )
                    }
                </Confirm>

                        <Modal show={this.props.isError}>
                            <Modal.Header>
                                <Modal.Title>Send Failed</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <p>{this.props.errorMessage}</p>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button onClick={() => this.setState({isError: false})}variant="primary">OK</Button>
                            </Modal.Footer>
                        </Modal>



            </React.Fragment>
        )
    }
}

export default InvitesReminders;
