import React, {Component} from 'react';
import TrafficLightSetting from './trafficLightSetting';
import Faker from 'faker';
import {API} from 'aws-amplify';

class TrafficLightSettings extends Component {

    constructor(props) {
        super(props);

        this.handleSetLimit = this.handleSetLimit.bind(this);

        this.state = {
            settings: [
                {
                    id: 1,
                    key: 'response',
                    label: '% Response Rate'
                },
                {
                    id: 2,
                    key: 'result',
                    label: '% Result Rate'
                },
                {
                    id: 3,
                    key: 'incidence',
                    label: '% Incidence Rate'
                },
                {
                    id: 4,
                    key: 'dropout',
                    label: '% Drop-out Rate'
                }
            ],
        }
    }

    async handleSetLimit(limit, colour, type) {

        const result = await API.patch('soaptwo', '/trafficlightbands/' + type, {body:
                {
                    colour: colour,
                    limit: limit
                }
        });

        let settingsCopy = JSON.parse(JSON.stringify(this.state.settings));

        settingsCopy.forEach(setting => {
           if(setting.key == type) {
               setting[colour] = limit;
           }
        });

        this.setState({settings: settingsCopy});

    }

    async componentDidMount(){

        const results = await API.get('soaptwo', '/trafficlightbands');

        const settings = this.state.settings;

        let newSetting = {};

        let newSettings = settings.map(setting => {

            newSetting = {};

             let correspondingResult = results.filter(result => result.rate_type == setting.key);

             if(correspondingResult[0]) {
                 newSetting.red = correspondingResult[0].red_limit ? correspondingResult[0].red_limit : 0;
                 newSetting.yellow = correspondingResult[0].yellow_limit ? correspondingResult[0].yellow_limit : 0;
             } else {
                 newSetting.red = 0;
                 newSetting.yellow = 0;
             }

            newSetting = {...newSetting, ...setting}

            return newSetting;
        });

        this.setState({settings: newSettings});

    }

    render() {
        return (
            <React.Fragment>
            { this.state.settings.map(setting =>
                    <TrafficLightSetting
                        setting = {setting}
                        onSetLimit = {this.handleSetLimit}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default TrafficLightSettings;