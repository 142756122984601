import React, { Component } from 'react';
import Confirm from "./confirm.jsx"
import { Link } from 'react-router-dom';

class Project extends Component {

    render(){
        return (
            <tr>
                {this.props.trafficLightLimits != "fetching" && this.props.project.projectInfo.map(function(item) {

                    let style = null;

                    let itemValue = item.value;

                    if(['responseRate', 'resultRate', 'incidenceRate', 'dropoutRate'].includes(item.key)) {

                        itemValue = Math.round(itemValue);

                        if(item.key == 'incidenceRate' && this.props.project.expectedIncidenceRate) {
                            if(this.props.project.expectedIncidenceRate < item.value) {
                                style = {backgroundColor: '#99ffa7'};
                            }
                            else if (
                                (this.props.project.expectedIncidenceRate - (this.props.project.expectedIncidenceRate * .25))
                                < item.value) {
                                style = {backgroundColor: '#fffc96'};
                            }
                            else {
                                style = {backgroundColor: '#ff8787'};
                            }
                        } else {

                            if (this.props.trafficLightLimits[item.key].yellow < item.value) {
                                style = {backgroundColor: '#99ffa7'};
                            } else if (this.props.trafficLightLimits[item.key].red < item.value) {
                                style = {backgroundColor: '#fffc96'};
                            } else {
                                style = {backgroundColor: '#ff8787'};
                            }
                        }
                    }

                    if(item.key == 'jobNumber') {
                        return (
                            <td>
                                <Link to={"/project-view/" + this.props.project.id} >{item.value}</Link>
                            </td>
                        )
                    }

                    if(item.key == 'clientName') {
                        return (
                            <td>
                                <a href={"/client-projects/" + this.props.project.clientId}>{item.value}</a>
                            </td>
                        )
                    }

                    if(['startDate', 'endDate', 'closedDate'].includes(item.key)) {
                        if(itemValue) {
                            itemValue = new Date(itemValue).toLocaleDateString("en-GB");
                        }
                    }

                    return (
                <td
                    style={style}
                    key={item.name}>{itemValue}</td>
                    )}, this)}
                <td className="nohov_btn" key="open">
                    <Link to={"/project-view/" + this.props.project.id} className="btn btn-primary openbtn">Open</Link>
                </td>
                <td className="nohov_btn" key="edit">
                    <Link to to={"/project-setup/" + this.props.project.id} className="btn btn-secondary">Edit</Link>
                </td>
                <td className="nohov_btn" key="delete">
                    { this.props.project.closedDate && (
                        <Confirm
                            actionTitle = "Delete Project"
                            actionDescription = {"delete the " + this.props.project.projectName + " project"}
                        >
                            {confirm => (
                                <button onClick={confirm(this.props.onDelete)}
                                        className="btn btn-danger" value={this.props.project.id}>Archive</button>
                            )}
                        </Confirm>
                    )}
                </td>
            </tr>
        )
    }
}
export default Project;
