import './custom.scss';
import React, { Component } from 'react';
import './App.css';
import { Route, Link, BrowserRouter as Router} from "react-router-dom";
import administration from "./components/administration";
import myDetails from "./components/myDetails";
import projectList from "./components/projectList";
import setupProject from "./components/setupProject";
import viewProject from "./components/viewProject";
import ArchivedProjectList from "./components/archivedProjectList";
import landing from "./components/landing";
import Login from "./components/login";
import PasswordReset from "./components/passwordReset"
import { Auth, API } from "aws-amplify";
import {Button} from "react-bootstrap";
import EmailTemplates from "./components/emailTemplates";
import PrivateRoute from "./components/privateRoute";
import Navigation from "./components/navigation";


class App extends Component {

    constructor(props) {
        super(props);

        this.userHasAuthenticated = this.userHasAuthenticated.bind(this)
        this.handleLogout = this.handleLogout.bind(this);
    }

    state = {
        isAuthenticated: false,
        isAuthenticating: true
    };

    async componentDidMount() {
        try {
            await Auth.currentSession();
            this.userHasAuthenticated(true);
        }
        catch(e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        this.setState({isAuthenticating: false})
    }

    userHasAuthenticated(authenticated) {

        (async function() {
            const userInfo = await Auth.currentUserInfo();

            await API.post('soapthree', '/users-stats', {
                    body: {username: userInfo.username}
                }
            );
        })();

        this.setState({
            isAuthenticated: authenticated
        });
    }

    async handleLogout() {

        await Auth.signOut();

        this.setState({
            isAuthenticated: false
        });
    }


    render() {

        const LoginPage = (props) => {
            return (
                <Login
                    isAuthenticated={this.state.isAuthenticated}
                    onUserHasAuthenticated={this.userHasAuthenticated}
                />
            );
        };

        return (

            !this.state.isAuthenticating &&
            <React.Fragment>
            <Router>
                <div>
                    <Route path="/login" exact render={LoginPage} />
                    <Route exact path="/password-reset" component={PasswordReset} />

                    <PrivateRoute authed={this.state.isAuthenticated} exact path="/" component={projectList} />
                    <PrivateRoute authed={this.state.isAuthenticated} exact path="/client-projects/:clientId?" component={projectList} />
                    <PrivateRoute authed={this.state.isAuthenticated} exact path="/administration" component={administration} />
                    <PrivateRoute authed={this.state.isAuthenticated} exact path="/my-details" component={myDetails} />
                    <PrivateRoute authed={this.state.isAuthenticated} exact path="/archived-projects" component={ArchivedProjectList} />
                    <PrivateRoute authed={this.state.isAuthenticated} exact path="/mail-template-management" component={EmailTemplates} />
                    <PrivateRoute authed={this.state.isAuthenticated} path="/project-setup/:id?" component={setupProject} />
                    <PrivateRoute authed={this.state.isAuthenticated} path="/project-view/:id" component={viewProject} />

                    <Route path="/landing" exact component={landing} />
                </div>
            </Router>
            </React.Fragment>
        )
    }
}

export default App;
