import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import Expandable from "./expandable"
import SearchResult from './searchResult.jsx'
import {API} from "aws-amplify";

class PanelVariables extends Component {

    constructor(props) {
        super(props);

        this.onPanelVariablesSearch = this.onPanelVariablesSearch.bind(this);
        this.handlePanelVariablesSearch = this.handlePanelVariablesSearch.bind(this);
        this.onAddVariable = this.onAddVariable.bind(this);
    }

    state = {
        panelVariables: "fetching",
        displayedPanelVariables: [],
        searchTerm: ""
    };

    onPanelVariablesSearch(event){

        this.setState({searchTerm: event.target.value.toLowerCase()})

        this.handlePanelVariablesSearch(this.state.searchTerm)
    }

    handlePanelVariablesSearch (searchTerm) {
        const selectedPanelVariableIds = this.props.selectedPanelVariables.map(
            variable => variable.id);

        let displayedPanelVariables = this.state.panelVariables.filter(variable => {
            return !selectedPanelVariableIds.includes(variable.id)
        });

        displayedPanelVariables = displayedPanelVariables.filter((variable) => {

            let varnameSearchValue = "";
            let labelSearchValue = "";

            if(variable.varname != null) {
                varnameSearchValue = variable.varname.toLowerCase();
            }

            if(variable.label != null) {
                labelSearchValue = variable.label.toLowerCase();
            }

            return varnameSearchValue.indexOf(searchTerm) !== -1 || labelSearchValue.indexOf(searchTerm) !== -1;
        });

        this.setState({
            displayedPanelVariables: displayedPanelVariables
        })
    }

    async onAddVariable(event){

        await this.props.onAddVariable(event);

        this.handlePanelVariablesSearch(this.state.searchTerm);
    }

    async componentDidMount() {

        try {
            const panelVariablesResult = await API.get("soap", "/panelvariables/");

            this.setState({panelVariables: panelVariablesResult});

        } catch (e) {
            alert(e)
        }

    }

    render() {
        return (
            <React.Fragment>

                {this.props.selectedPanelVariables.map(variable =>
                    <React.Fragment>
                        <br></br>
                        <h2>{variable.varname}</h2>
                        <br></br>
                        <p>{variable.label}</p>
                        <br></br>
                        {variable.categories &&
                            <Expandable title="answers">
                                {variable.categories.map(answer =>
                                    <p>{answer.label}</p>
                                )}
                            </Expandable>

                        }
                        <br></br>
                    </React.Fragment>
                )}

                {this.state.panelVariables != "fetching" && (
                <Form.Group >
                <Form.Label > Search Variables to Add</Form.Label>
                    <Form.Control type="text" onChange={this.onPanelVariablesSearch}/>
                    </Form.Group>
                )}
            {this.state.displayedPanelVariables.map(variable =>
            <SearchResult
                resultId={variable.id}
                resultName={variable.label + " [" + variable.varname +"]"}
                onAdd={this.onAddVariable}
            />
        )}
            </React.Fragment>
        )
    }
}

export default PanelVariables;
