import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import {API} from 'aws-amplify';
import ErrorDisplayingFormField from "./errorDisplayingFormField";

class SupplierUrlForYouthsight extends Component {

    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    state = {...this.props.source, errors:{}};

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSave(inputField) {

        const endPoint = "/projects/" +
            this.props.source.project_id +
            '/suppliers/' + this.props.source.supplier_id;

        let input = {};

        input[inputField] = this.state[inputField];

        const result = await API.patch('soaptwo', endPoint, {
            body: input
        });

        console.log(result);

        if(result.code == 1000) {
            this.setState({errors: result.errors});
        } else {
            this.setState({errors: {}});
        }

    }

    render(){
        return (
            <React.Fragment>
            <br></br>
                <h2>{this.props.source.name}</h2>

                <Form.Group>
                    <Form.Label>Respondent ID Param</Form.Label>
                    <Form.Control
                        type="text"
                        name="respondent_id_param"
                        onChange={this.handleInputChange}
                        value={this.state.respondent_id_param}
                    />
                    <Button
                        className="supplier_btn"
                        vartiant="primary"
                        onClick={() => this.handleSave('respondent_id_param')}
                    >Save</Button>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Project ID Param</Form.Label>
                    <Form.Control
                        type="text"
                        name="project_id_param"
                        onChange={this.handleInputChange}
                        value={this.state.project_id_param}
                    />
                    <Button
                        className="supplier_btn"
                        vartiant="primary"
                        onClick={() => this.handleSave('project_id_param')}
                    >Save</Button>
                </Form.Group>

                <Form.Group>
                    <ErrorDisplayingFormField
                        type="text"
                        name="completed_url"
                        onChange={this.handleInputChange}
                        value={this.state.completed_url}
                        errors={this.state.errors}
                        label="Completed URL"
                    />

                    <Button
                        className="supplier_btn"
                        vartiant="primary"
                        onClick={() => this.handleSave('completed_url')}
                    >Save</Button>
                </Form.Group>

                <Form.Group>
                    <ErrorDisplayingFormField
                        type="text"
                        name="screenout_url"
                        onChange={this.handleInputChange}
                        value={this.state.screenout_url}
                        errors={this.state.errors}
                        label="Screened Out URL"
                    />
                    <Button
                        className="supplier_btn"
                        vartiant="primary"
                        onClick={() => this.handleSave('screenout_url')}
                    >Save</Button>
                </Form.Group>

                <Form.Group>
                    <ErrorDisplayingFormField
                        type="text"
                        name="quota_full_url"
                        onChange={this.handleInputChange}
                        value={this.state.quota_full_url}
                        errors={this.state.errors}
                        label="Quota Full URL"
                    />
                    <Button
                        className="supplier_btn"
                        vartiant="primary"
                        onClick={() => this.handleSave('quota_full_url')}
                    >Save</Button>
                </Form.Group>

                <Form.Group>
                    <ErrorDisplayingFormField
                        type="text"
                        name="quality_fail_url"
                        onChange={this.handleInputChange}
                        value={this.state.quality_fail_url}
                        errors={this.state.errors}
                        label="Rejected URL"
                    />
                    <Button
                        className="supplier_btn"
                        vartiant="primary"
                        onClick={() => this.handleSave('quality_fail_url')}
                    >Save</Button>
                </Form.Group>
                <br></br>
                <hr></hr>
            </React.Fragment>
        )
    }
}

export default SupplierUrlForYouthsight;
