import React, {Component} from 'react';
import {Button, Form, FormControl} from "react-bootstrap";

class FilterDefinition extends Component {

    constructor(props){
        super(props);

        this.handleDefinitionCursorMove = this.handleDefinitionCursorMove.bind(this);
        this.handleAddVariable = this.handleAddVariable.bind(this);
        this.handleAddAnswer = this.handleAddAnswer.bind(this);

        this.definitionFieldRef = React.createRef();
    }

    state = {};

    handleDefinitionCursorMove(event) {

        const definitionCursorPosition = event.target.selectionStart;

        this.setState({definitionCursorPosition});

        let leftOfCursor = this.props.newFilterDefinition.slice(0, definitionCursorPosition);

        let currentVarname = leftOfCursor.match(/\w+\s*!?=\s*$/);

        if(currentVarname && currentVarname[0]) {

            currentVarname  = currentVarname[0].match(/^\w+/)[0];

        }

        this.setState({panelVariableToHintValuesFor: currentVarname});


    }

    handleAddVariable(event) {

        const target = event.target;
        const varname = target.value;

        const newFilterDefinition = this.props.newFilterDefinition.slice(0, this.state.definitionCursorPosition) +
            varname + this.props.newFilterDefinition.slice(this.state.definitionCursorPosition);

        this.props.onDefinitionChange(newFilterDefinition);

        this.definitionFieldRef.focus();
    }

    handleAddAnswer(event) {
        const target = event.target;
        const answerCode = target.value;

        const newFilterDefinition = this.props.newFilterDefinition.slice(0, this.state.definitionCursorPosition) +
            answerCode + this.props.newFilterDefinition.slice(this.state.definitionCursorPosition);

        this.props.onDefinitionChange(newFilterDefinition);

        this.definitionFieldRef.focus();
    }

    render() {

        return (
        <React.Fragment>

<Form.Group>
    <Form.Label>Definition <br/>
        <b>( )</b> for parens<br/>
        <b>=</b> for equals<br/>
        <b>!=</b> for not equals<br/>
        <b>&amp;</b> for and<br/>
        <b>&#124;</b> for or<br/>
        <b>&gt;</b> for greater than<br/>
        <b>&lt;</b> for less than
    </Form.Label>
    <Form.Control
        type="text"
        name="newFilterDefinition"
        onChange={(event) => {this.props.onDefinitionChange(event.target.value)}}
        onKeyUp={this.handleDefinitionCursorMove}
        value={this.props.newFilterDefinition}
        ref={(input) => {this.definitionFieldRef = input}}
        isInvalid={this.props.errors}
    />
    {this.props.errors && (
        <FormControl.Feedback
            type="invalid"
        >{this.props.errors.message}</FormControl.Feedback>
    )}
</Form.Group>

{this.state.panelVariableToHintValuesFor &&
this.props.availablePanelVariables.filter(variable => {
    return variable.varname == this.state.panelVariableToHintValuesFor;
}).length &&
this.props.availablePanelVariables.filter(variable => {
    return variable.varname == this.state.panelVariableToHintValuesFor;
})[0].categories && (

    <div>
        <h4>Available Answers</h4>

        {this.props.availablePanelVariables.filter(variable => {
            return variable.varname == this.state.panelVariableToHintValuesFor;
        })[0].categories.map(answer =>

            <Button
                variant="primary"
                value={answer.code}
                onClick={this.handleAddAnswer}
            >{answer.label}</Button>

        )}

    </div>

)}


<div>

    <h4>Available Variables</h4>

    {this.props.availablePanelVariables.map(variable =>

        <Button
            variant="primary"
            value={variable.varname}
            onClick={this.handleAddVariable}
        >{variable.label}</Button>
    )}


</div>

        </React.Fragment>
    )}
    }

export default FilterDefinition;